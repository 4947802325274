import {useEffect, useMemo, useState} from "react";
import api from '../../../js/api';
import * as Yup from "yup";
import {useFormik, FormikProvider} from 'formik';
import {FormRol} from "./FormRol";
import {useNavigate, useParams} from "react-router-dom";
import ContainerComponent from "../../../components/elements/ContainerComponent";
import {LoaderEllipsisComponent} from "../../../components/elements/Loader/LoaderComponent";


export function CreateRole(props) {
  const {isView = false, isUpdate = false, loader, retrieve, item} = props;
  const initialValues = useMemo(() => (item), [item]);
  const [screens, setViews] = useState([]);
  const [loading, setLoading] = useState(false);
  const [didMount, setMount] = useState(false);
  const nav = useNavigate();
  const {id} = useParams();

  useEffect(() => {
    setMount(true);
  }, []);

  useEffect(() => {
    if (id && didMount) {
      retrieve(id);
    }
    if (didMount) {
      setLoading(true)
      api.get('role/views')
        .then((res) => setViews(res.data))
        .finally(() => setLoading(false))
    }
  }, [id, retrieve, didMount]);

  const schema = Yup.object().shape({
    name: Yup.string().required('Campo requerido'),
    views: Yup.array()
      .of(
        Yup.object().shape({
          view: Yup.string().required('Requerido'),
          create: Yup.boolean().required('Requerido'),
          edit: Yup.boolean().required('Requerido'),
          read: Yup.boolean().required('Requerido')
        })
      )
      // .required('Debe agregar una vista') // these constraints are shown if and only if inner constraints are satisfied
      .min(1, 'Mínimo 1 vista'),
  });

  const handleSubmit = (data) => {
    if (id) {
      props.update(id, data, nav)
    } else {
      props.create(data, nav)
    }
  }

  const formikSetting = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validateOnMount: true,
  })
  const {views} = formikSetting.values;

  useEffect(()=>{
    if (views) {
      const idSelected = views.map(viewSelected => viewSelected.view);
      const newScreensValue = screens.map((screen)=> {
        screen.disabled = idSelected.includes(screen.value)
        return screen
      })
      setViews(newScreensValue)
    }
  }, [views])

  const goBack = () => nav('/roles');

  return (
    <>
      <ContainerComponent
        title={isUpdate ? "Editar Rol" : isView ? `Rol ${id}` : "Crear Rol"}
      >
        <div>
          <LoaderEllipsisComponent loader={loader}/>
          {
            didMount &&
              <FormikProvider
                value={formikSetting}
              >
                <FormRol
                  screens={screens}
                  goBack={goBack}
                  isValid={formikSetting.isValid}
                  isUpdate={isUpdate}
                  isView={isView}
                  loading={loading}
                  values={views}
                />
              </FormikProvider>
          }
        </div>
      </ContainerComponent>
    </>
  )
}