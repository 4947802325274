import {useEffect, useState} from "react";
import {Button, Input, Tooltip} from "@material-tailwind/react";
import {MagnifyingGlassIcon} from "@heroicons/react/24/solid";

function SearchInput(props) {
  const {onClickSearch, searchValue} = props;
  const [search, setSearch] = useState("");
  const [mounted, setMount] = useState(false);
  useEffect(() => {
    setMount(true)
  }, [])

  useEffect(() => {
    if (mounted) {
      setSearch(searchValue)
    }
  }, [mounted])

  const onChange = ({target}) => {
    if (target.value === '') {
      onClickSearch('')
    }
    setSearch(target.value)
  };

  return (
    <div className="relative w-full flex justify-between">
      <Input
        type="text"
        value={search}
        placeholder="Buscar"
        autoComplete="off"
        onChange={onChange}
        className="!mt-0 !border-r-0 h-full focus:!border-blue-gray-200"
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            onClickSearch(search)
          }
        }}
        containerProps={{
          className: "min-w-[0px] !border-r-0 !pb-0 h-9",
        }}
      />
      <Tooltip content="Presiona para buscar" placement="top">
        <Button
          size="sm"
          onClick={event => onClickSearch(search)}
          color={"gray"}
          disabled={!search}
          className={`!border !border-blue-gray-200 rounded-none h-9 ${search && 'bg-blue-gray-100'}`}
        >
          {/*<PaperAirplaneIcon className={`w-4 h-4 rotate-[315deg] ${search ? '' : 'opacity-50'}`}></PaperAirplaneIcon>*/}
          <MagnifyingGlassIcon className={`w-5 h-5 ${search ? '' : 'opacity-50'}`}></MagnifyingGlassIcon>
        </Button>
      </Tooltip>

    </div>
  );
}

export {SearchInput}