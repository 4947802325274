import React, {useEffect, useState} from "react";
import {Input, Popover, PopoverContent, PopoverHandler,} from "@material-tailwind/react";
import {CalendarIcon} from "@heroicons/react/20/solid";
import {CustomCaption} from "./DateComponent";
import {toastError} from "../../../js/toast";
import {DayPicker} from "react-day-picker";
import esLocale from "date-fns/locale/es";
import {format} from "date-fns";

export function SimpleDateComponent(props) {
  const {initialDate, placeholder, callOnChange} = props;
  const [didMount, setMount] = useState(false);
  const [date, setDate] = useState();

  useEffect(() => {
    setMount(true)
  }, []);

  // useEffect(() => {
  //   if (didMount && initialDate) {
  //     // console.log('Cambio', props.placeholder)
  //     // setDate(initialDate);
  //   }
  // }, [didMount, initialDate]);

  const resetDate = async () => {
    setDate(undefined);
  };

  const validateSelectDate = async (selectedDate) => {
    if (selectedDate) {
      if (selectedDate > props.maxdate) {
        toastError('La fecha seleccionada supera la fecha permitida')
      } else if (selectedDate < props.mindate) {
        toastError('La fecha seleccionada es menor la fecha permitida')
      } else {
        setDate(selectedDate);
        callOnChange(format(selectedDate, "yyyy-MM-dd", {locale: esLocale}));
      }
    }
  }

  return (
    <Popover placement="bottom" className="w-full">
      <PopoverHandler>
        <div className="w-full">
          <Input
            id='calendar'
            variant='static'
            className="cursor-pointer pr-24 !mt-0"
            size="md"
            readOnly
            placeholder={placeholder}
            type="text"
            onChange={() => null}
            value={date ? format(date, "dd/MM/yyyy", {locale: esLocale}) : ""}
            icon={
              <div
                className={`right-3 ml-[-20px] w-10 !mt-[-10px] h-5 cursor-pointer flex flex-row-reverse justify-between ${props.disabled && '!cursor-not-allowed'}`}>
                <PopoverHandler><CalendarIcon/></PopoverHandler>
              </div>
            }
          />
          <div className="absolute font-normal text-sm !mt-[-60px] text-black">
            {placeholder}
          </div>
        </div>

      </PopoverHandler>

      <PopoverContent>
        <DayPicker
          mode="single"
          selected={date}
          showOutsideDays
          onSelect={validateSelectDate}
          className="border-0"
          locale={esLocale}
          components={{
            Caption: CustomCaption
          }}
        />
      </PopoverContent>
    </Popover>
  );
}