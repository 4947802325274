import {
  BanknotesIcon,
  ChartPieIcon,
  CreditCardIcon,
  CubeIcon,
  IdentificationIcon,
  TagIcon,
  UserIcon,
  ArchiveBoxArrowDownIcon,
  ClipboardDocumentListIcon,
} from "@heroicons/react/24/solid";
import React from "react";

const Menu = [
  {title: "Panel", icon: <ChartPieIcon className="w-5 h-5"/>, _path: "/",},
  {
    title: "Gestion de Productos", icon:
      <img src="/assets/administrar_producto.svg" alt="" className="w-5 h-5"/>,
    _path: "/producto",
    children: [
      {title: "Categorías", icon: <TagIcon className="w-5 h-5"/>, _path: "/producto/categoria"},
      {title: "Productos", icon: <CubeIcon className="w-5 h-5"/>, _path: "/producto"},
      {title: "Inventario", icon: <ArchiveBoxArrowDownIcon className="w-5 h-5"/>, _path: "/inventario"},
    ]
  },
  {
    title: "Gestion de Usuarios",
    icon: <img src="/assets/administrar_usuarios.svg" alt="" className="w-5 h-5"/>,
    _path: "/usuario",
    children: [
      {title: "Usuarios", icon: <UserIcon className="w-5 h-5"/>, _path: "/usuario"},
      // {title: "Roles", icon: <LockClosedIcon className="w-5 h-5"/>, _path: "/roles"},
    ]
  },
  {
    title: "Gestion de Personas",
    icon: <img src="/assets/administrar_personas.svg" alt="" className="w-5 h-5"/>,
    _path: "/cliente",
    children: [
      {title: "Clientes", icon: <IdentificationIcon className="w-5 h-5"/>, _path: "/cliente"},
      {title: "Proveedores", icon: <UserIcon className="w-5 h-5"/>, _path: "/proveedor"},
    ]
  },
  {
    title: "Gestion de Ventas",
    icon: <img src="/assets/administrar_ventas.svg" alt="" className="w-5 h-5"/>,
    _path: "/venta",
    children: [
      {title: "Ventas", icon: <BanknotesIcon className="w-5 h-5"/>, _path: "/venta"},
      {title: 'Creditos', icon: <CreditCardIcon className="w-5 h-5"/>, _path: '/venta-credito'},
    ]
  },
  {
    title: "Gestion de Compras",
    icon: <img src="/assets/administrar_compras.svg" alt="" className="w-5 h-5"/>,
    _path: "/compra",
    children: [
      {
        title: 'Compras',
        icon: <img src="/assets/compras.svg" alt="" className="w-5 h-5"/>,
        _path: '/compra'
      },
    ]
  },
  {
    title: "Gestion de Sucursales",
    icon: <img src="/assets/administrarsucursal.svg" alt="" className="w-5 h-5"/>,
    _path: "/sucursal",
    children: [
      {
        title: 'Sucursales',
        icon: <img src="/assets/sucursales.svg" alt="" className="w-5 h-5"/>,
        _path: '/sucursal'
      },
      {
        title: 'Bodegas', icon: <img src="/assets/bodegas.svg" alt="" className="w-5 h-5"/>,
        _path: '/bodega'
      },
    ]
  },
  {title: "Reportes", icon: <ClipboardDocumentListIcon className="w-5 h-5"/>, _path: "/reporte"},
];

export default Menu;