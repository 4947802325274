import { useState, useEffect } from 'react'
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  Typography,
} from "@material-tailwind/react";
import { BillFormat } from './BillFormat';
import { setMoney } from '../../../utils/shared';

export function AnullationModal(props) {
  const [didMount, setMount] = useState(false);
  const { row, retrieve, closeModal, show, item, canlledAction } = props;
  const { sale_details } = item;

  useEffect(() => {
    setMount(true)
  }, [])

  useEffect(() => {
    if (didMount && row?.id) retrieve(row.id)
  }, [didMount, row])

  const handleOpen = () => closeModal(show === true ? 0 : 3);

  const confirmAction = () => {
    canlledAction()
  }



  return (
    <>
      <Dialog
        open={show}
        handler={handleOpen}
        size="sm"
        dismiss={{ outsidePress: false }}
      >
        <DialogBody className="grid place-items-center gap-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="red"
            className="mr-3 h-6 w-6 right-2 top-5 absolute cursor-pointer"
            onClick={handleOpen}
          >
            <path
              fillRule="evenodd"
              d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
              clipRule="evenodd"
            />
          </svg>
          <Typography color="red" variant="h4" className='mt-3'>
            ¡Anulación de venta!
          </Typography>
          <div className='w-full'>
            <Typography
              color="blue-gray"
              variant="h1"
              className='mt-3 line-clamp-1 leading-[1] opacity-20 text-[55px] absolute text-anullation'
            >
              Anulado
            </Typography>
            <BillFormat
              item={item}
              items={sale_details ?? []}
              componentRef={null}
              totalSales={setMoney(row.total ?? 0)}
            />
          </div>
        </DialogBody>
        <DialogFooter className="pt-0 pb-4">
          <Typography color="gray" variant="small" className='mt-3 leading-1'>
            Esta acción no se podrá revertir, confirma si deseas anular esta venta o presiona la X para salir.
          </Typography>
          <div className="w-full flex md:flex-row lg:flex-row flex-col justify-center gap-3 mt-2">
            <Button variant="gradient" onClick={confirmAction} color="red" className="">
              Sí, Anular
            </Button>
          </div>

        </DialogFooter>
      </Dialog>
    </>
  )
}
