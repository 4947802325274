import {useState, useEffect} from 'react'
import Container from "../../../components/elements/ContainerComponent"
import {TableWithStripedRows} from "../../../components/elements/Tables/TableComponent";
import {
  NoSymbolIcon,
} from "@heroicons/react/24/solid";
import {
  IconButton,
} from "@material-tailwind/react"
import {BillModal} from './BillModal';
import {AnullationModal} from './AnullationModal';
import {useLocation, useNavigate} from "react-router-dom";
import {toastError} from '../../../js/toast'
import {ClipboardDocumentCheckIcon} from "@heroicons/react/24/outline";
import {connect} from "react-redux";

export function ListSales(props) {
  const [didMount, setMount] = useState(false);
  const [idModal, setIdModal] = useState(0);
  const [currentRow, setCurrentRow] = useState({});
  const {setItem, user, filterById} = props;
  const nav = useNavigate();

  useEffect(() => {
    setMount(true)
  }, [])

  useEffect(() => {
    if (didMount && user) StatusCaja();
  }, [didMount, user])

  useEffect(() => {
    if (didMount) props.list()
  }, [didMount])

  const columnsName = [
    {selector: row => row.id, name: 'ID'},
    {selector: row => row.creation_date, name: 'Fecha y Hora', variant: 'datetime'},
    {selector: row => row.nit, name: 'NIT'},
    {selector: row => row.status, name: 'Estado'},
    {selector: row => row.total, name: 'Total'},
    {
      selector: row => row.id,
      name: 'Acciones',
      cell: row => <ActionTableConn
        row={row}
        showCancellation={confirmAnulationModal}
        viewDetail={viewDetail}/>
    }

  ]
  const StatusCaja = () => {
    let localUser = localStorage.getItem('user')
    if (localUser) {
      localUser = JSON.parse(localUser)
      if (!localUser?.cash_register) {
        toastError('No tiene una caja abierta')
        nav('/ventas')
      }
    }
  }
  const viewDetail = (row) => {
    setCurrentRow(row)
    setIdModal(1)
  }
  const canlledAction = () => {

    const data = {
      id: currentRow.id
    }
    props.cancelSale(data, nav)
    resetInfoModal(0)
  }
  const confirmAnulationModal = (row) => {
    setCurrentRow(row)
    setIdModal(3)
  }

  const resetInfoModal = (_idModal) => {
    setIdModal(_idModal)
    setCurrentRow({})
    setItem({})
  }

  const Filters = [
    {
      placeholder: 'Fecha Inicio',
      name_filter: 'date_start',
      // maxdate: filterById?.date_end,
      initialDate: new Date(),
    },
    {
      placeholder: 'Fecha Fin',
      name_filter: 'date_end',
      // mindate: filterById?.date_start,
      initialDate: new Date(),
    }
  ]


  return (
    <Container
      title="Ventas"
      textBtnCreate="Registrar venta"
      uri="/venta"
    >
      <BillModal
        show={idModal === 1}
        row={currentRow}
        closeModal={resetInfoModal}
        {...props}
      />
      <AnullationModal
        show={idModal === 3}
        row={currentRow}
        canlledAction={canlledAction}
        closeModal={resetInfoModal}
        {...props}
      />
      <TableWithStripedRows
        {...props}
        filters={Filters}
        dontShowAction={true}
        columns={columnsName}
        searchValue={props.search}
        actionDelete={props.deleteRecord}
        changeFilter={props.changeFilterById}
      />
    </Container>
  )
}

const ActionTable = ({row, showCancellation, viewDetail, user}) => {
  const [hasPermission, setPermission] = useState(false);
  const [hasPermissionCancel, setPermissionCancel] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location && user && row) {
      if (user.views === 'is_superuser') {
        setPermission(true)
        setPermissionCancel(!(row.status === 'Anulado'))
      } else {
        if (user.views?.length) {
          user.views.forEach((view) => {

            let split = view.view_f.split(',');

            split.forEach((item) => {
              item = item.trim();
              // if (item === location.pathname && ele.read) {
              //   setPermission(true)
              // }
              // if (item === location.pathname && ele.edit) {
              //   setPermissionEdit(true)
              // }

              if (item === location.pathname && view.read) {
                setPermission(true)
              }
              if (item === location.pathname && view.edit) {
                setPermissionCancel(!(row.status === 'Anulado'))
              }
            })
          })
        }
      }
    }
  }, [user, row]);


  return (
    <div className="flex">
      <IconButton
        variant="text"
        className={`py-0 my-0 text-amber-600 ${!hasPermission && 'hidden'}`}
        onClick={() => viewDetail(row)}
      >
        <ClipboardDocumentCheckIcon className="w-6"/>
      </IconButton>
      <IconButton
        variant="text"
        className={`py-0 my-0 text-red-600 ${!hasPermissionCancel && 'hidden'}`}
        onClick={() => showCancellation(row)}>
        <NoSymbolIcon className="w-6"/>
      </IconButton>
    </div>
  )

}

const States = (state) => {
  return {
    user: state.user.data
  };
};

const ActionTableConn = connect(States, undefined)(ActionTable);