import {Card, Typography} from "@material-tailwind/react";

export function CardStatistics({title, value, legend, icon, background, textcolor, textColorPargraph}) {
  return (
    <Card className={`px-4 py-1 min-h-[150px] rounded-lg ${background}`}>
      {/* HEADER */}
      <div className="w-full py-2">
        <Typography className={`pt-1 text-start ${textcolor}`} variant="h6">
          {title}
        </Typography>
      </div>
      {/* BODY */}
      <div className="w-full flex flex-col justify-between">
        <div className="flex items-center justify-between w-full">
          <div
            className="w-min p-2 rounded-2xl shadow-md shadow-gray-200 justify-start text-green-800 text-center flex bg-white">
            {icon}
          </div>
          <Typography className={`ml-2 ${textcolor}`} variant="h4">
            {value}
          </Typography>
        </div>
        <Typography variant="paragraph" className={`${textcolor} my-2`}>
          {legend ?? ''}
        </Typography>
      </div>
    </Card>
  )
}