import {createSlice} from '@reduxjs/toolkit'
import api from '../../api';
import {toastError, toastSuccess} from '../../toast';

export const createReducer = (storeId, endpoint, initialPage = undefined) => {

  const slice = createSlice({
    name: storeId, initialState: {
      data: {
        results: [],
        count: 0,
      },
      dataBackup: [],
      page: 1,
      rowPerPage: 10,
      localFilterBy: undefined,
      currentTab: null,
      item: {},
      search: '',
      filterById: null,
      loader: false,
      ordering: '',
    }, reducers: {
      // PURE REDUCER
      setData: (state, action) => {
        state.data = action.payload
      },
      setRowPerPage: (state, action) => {
        state.rowPerPage = action.payload
      },
      setDataBackup: (state, action) => {
        state.dataBackup = action.payload
      },
      setLoader: (state, action) => {
        state.loader = action.payload
      },
      setPage: (state, action) => {
        state.page = action.payload
      },
      setItem: (state, action) => {
        state.item = action.payload
      },
      setSearch: (state, action) => {
        state.search = action.payload;
      },
      setFilterById: (state, action) => {
        state.filterById = action.payload;
      },
      setCurrentTab: (state, action) => {
        state.currentTab = action.payload
      },
      setOrdering: (state, action) => {
        state.ordering = action.payload
      },
      setLocalFilterBy: (state, action) => {
        state.localFilterBy = action.payload
      },
      resetState: (state, action) => {
        let newData = {
          results: [],
          count: 0,
        }
        state.data = newData;
        // state.page = 1;
        // state.item = {};
        // state.search = '';
        // state.loader = false;
        // state.ordering = '';
      },
    }
  });

  const {actions} = slice;
  const {
    setLoader,
    setOrdering,
    setData,
    setDataBackup,
    setPage,
    setItem,
    setSearch,
    setRowPerPage,
    setFilterById,
  } = actions

  // -----------------------------------
  // Actions
  // -----------------------------------

  const list = (page = 1) => (dispatch, getStore) => {
    const resource = getStore()[storeId];
    const params = {page, page_size: resource.rowPerPage};
    // params.ordering = resource.ordering;
    params.search = resource.localFilterBy ? "" : resource.search;
    if (resource.filterById && typeof (resource.filterById) === "object") {
      for (const filterKey in resource.filterById) {
        params[filterKey] = resource.filterById[filterKey]
      }
    }
    dispatch(setLoader(true));
    dispatch(setItem({}));
    api.get(endpoint, params)
      .then((response) => {
        dispatch(setData(response.data));
        if (resource.localFilterBy) {
          dispatch(setDataBackup(response.data.results));
          dispatch(filterTable(resource.search, resource.localFilterBy, response.data.results))
        }
        dispatch(setPage(page));
      })
      .catch(() => {
      })
      .finally(() => {
        dispatch(setLoader(false));
      });
  };

  const ChangeRowsPerPage = (rowPerPage = 10) => (dispatch, getStore) => {
    const resource = getStore()[storeId];
    const params = {page_size: rowPerPage, page: 1};
    // params.ordering = resource.ordering;
    params.search = resource.localFilterBy ? "" : resource.search;

    dispatch(setLoader(true));
    dispatch(setItem({}));
    api.get(endpoint, params)
      .then((response) => {
        dispatch(setData(response.data));
        if (resource.localFilterBy) {
          dispatch(setDataBackup(response.data.results));
          dispatch(filterTable(resource.search, resource.localFilterBy, response.data.results))
        }
        dispatch(setRowPerPage(rowPerPage));
      })
      .catch(() => {
      })
      .finally(() => {
        dispatch(setLoader(false));
      });
  };

  const retrieve = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${endpoint}/${id}`)
      .then((response) => {
        dispatch(setItem(response.data));
      })
      .catch((reason) => {
        toastError(reason)
      })
      .finally(() => {
        dispatch(setLoader(false));
      });
  };

  const create = (data, nav) => (dispatch) => {
    dispatch(setLoader(true));
    api.post(endpoint, data)
      .then(() => {
        toastSuccess('Registro creada')
        if (!!initialPage && !!nav) nav(initialPage);
      })
      .catch((reason) => {
        if (reason?.response?.data?.detail) {
          toastError(reason?.response?.data?.detail)
        } else {
          toastError('Registro no creado')
        }
      })
      .finally(() => {
        dispatch(setLoader(false));
      });
  };

  const createWithCallback = (data, functionCallback) => (dispatch) => {
    dispatch(setLoader(true));
    api.post(endpoint, data)
      .then(() => {
        toastSuccess('Registro creada')
        if (!!functionCallback) functionCallback();
      })
      .catch((reason) => {
        if (reason?.response?.data?.detail) {
          toastError(reason?.response?.data?.detail)
        } else {
          toastError('Registro no creado')
        }
      })
      .finally(() => {
        dispatch(setLoader(false));
      });
  };

  const update = (id, data, nav) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`${endpoint}/${id}`, data)
      .then(() => {
        toastSuccess('Registro actualizada')
        if (!!initialPage) nav(initialPage);
      })
      .catch((reason) => {
        console.log('reason', reason);
        if (reason?.response?.data?.detail) {
          toastError(reason?.response?.data?.detail)
        } else {
          toastError('Registro no actualizado')
        }
      })
      .finally(() => {
        dispatch(setLoader(false));
      });
  };

  const cancellation = (data) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`${endpoint}/cancellation`, data)
      .then(() => {
        toastSuccess('Registro anulado')
        dispatch(list(1));
      })
      .catch((reason) => {
        if (reason?.response?.data?.detail) {
          toastError(reason?.response?.data?.detail)
        } else {
          toastError('Registro no anulado')
        }
      })
      .finally(() => {
        dispatch(setLoader(false));
      });
  };

  const cancelSale = (data) => (dispatch) => {
    dispatch(setLoader(true));

    api.put(`${endpoint}/cancel_sale`, data)
      .then(() => {
        toastSuccess('Venta anulada')
        dispatch(list(1));
      })
      .catch((reason) => {
        if (reason?.response?.data?.detail) {
          toastError(reason?.response?.data?.detail)
        } else {
          toastError('La venta no se pudo anular')
        }
      })
      .finally(() => {
        dispatch(setLoader(false));
      });
  }

  const updateAttach = (id, data, nav) => (dispatch) => {
    dispatch(setLoader(true));
    api.putFormData(`${endpoint}/${id}`, data)
      .then(() => {
        toastSuccess('Registro actualizada')
        if (!!initialPage) nav(initialPage);
      })
      .catch((reason) => {
        console.log('reason', reason);
        if (reason?.response?.data?.detail) {
          toastError(reason?.response?.data?.detail)
        } else {
          toastError('Registro no actualizado')
        }
      })
      .finally(() => {
        dispatch(setLoader(false));
      });
  };

  const createAttach = (data, nav) => (dispatch) => {
    dispatch(setLoader(true));
    api.postFormData(endpoint, data)
      .then(() => {
        toastSuccess('Registro actualizada')
        if (!!initialPage) nav(initialPage);
      })
      .catch((reason) => {
        console.log('reason', reason);
        if (reason?.response?.data?.detail) {
          toastError(reason?.response?.data?.detail)
        } else {
          toastError('Registro no actualizado')
        }
      })
      .finally(() => {
        dispatch(setLoader(false));
      });
  };

  const deleteRecord = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.remove(`${endpoint}/${id}`)
      .then(() => {
        toastSuccess('Registro Eliminado')
        dispatch(list());

      })
      .catch(() => {
        toastError('Registro no eliminado')
      })
      .finally(() => {
        dispatch(setLoader(false));
      });
  };

  const filterTable = (search, filterBy, data) => (dispatch) => {
    dispatch(setLoader(true));
    let newData = {
      results: [],
      count: 0,
    }
    newData.results = data.filter((item) => item[filterBy].toLowerCase().includes(search.toLowerCase()))
    newData.count = newData.results.length;
    dispatch(setData(newData))
    dispatch(setLoader(false));

  };

  const searchChange = (search) => (dispatch, getStore) => {
    const dataStore = getStore()[storeId];
    const {localFilterBy, dataBackup} = dataStore;
    dispatch(setSearch(search));
    if (localFilterBy) {
      dispatch(filterTable(search, localFilterBy, dataBackup));
    } else {
      dispatch(list());
    }
  };

  const changeFilterById = (filterValue) => (dispatch, getStore) => {
    const {filterById} = getStore()[storeId];
    if (filterById && typeof (filterById) === 'object') {
      // Si ya existe un filtro que lo mantenga, y que sobrescriba su valor
      dispatch(setFilterById({...filterById, ...filterValue}));
    } else {
      // de lo contrario que agregue ese filtro
      dispatch(setFilterById(filterValue));
    }
    dispatch(list());
  };

  const onSortChange = (ordering) => (dispatch, getStore) => {
    const sort = getStore()[storeId].ordering;
    if (ordering === sort) {
      dispatch(setOrdering(`-${ordering}`));
    } else {
      dispatch(setOrdering(ordering));
    }
    dispatch(list());
  };

  const extraActions = {
    list,
    retrieve,
    create,
    update,
    deleteRecord,
    searchChange,
    onSortChange,
    createAttach,
    updateAttach,
    cancellation,
    setLoader,
    ChangeRowsPerPage,
    changeFilterById,
    cancelSale,
    createWithCallback,
  };

  return {slice, extraActions}

}