import {useEffect, useState} from "react";
import ContainerComponent from "../../../components/elements/ContainerComponent";
import {TableWithStripedRows} from "../../../components/elements/Tables/TableComponent";

export function UserList(props) {
  const [didMount, setDidMount] = useState(false);
  const {list} = props;

  useEffect(() => {
    setDidMount(true);
  }, []);
  
  useEffect(() => {
    if(didMount){
      list();
    }
  }, [didMount, list]);


  const columnsName = [
    {
      selector: row => row.id,
      name: 'Id',
    },
    // {
    //   selector: row => row.person.photo,
    //   name: 'Foto',
    //   variant: 'image'
    // },
    {
      selector: row => row.branch_office_name,
      name: 'Sucursal',
    },
    {
      selector: row => row.role_name,
      name: 'Rol',
    },
    {
      selector: row => row.person.names,
      name: 'Nombres',
    },
    {
      selector: row => row.person.last_names,
      name: 'Apellidos',
    },
    {
      selector: row => row.person.phone_number,
      name: 'Teléfono',
    },
    {
      selector: row => row.person.email,
      name: 'Correo',
    },

  ]

  const Filters = [
    {
      placeholder: 'Sucursal',
      name_filter: 'branch_office_id',
      urlOptionList: 'branch-offices/select'
    },
    {
      placeholder: 'Rol',
      name_filter: 'rol_id',
      urlOptionList: 'role/select_option'
    }
  ]

  return (
    <ContainerComponent
      uri={'crear'}
      title="Usuarios"
      textBtnCreate={'Crear Usuarios'}
    >
      <TableWithStripedRows
        {...props}
        filters={Filters}
        changeFilter={props.changeFilterById}
        columns={columnsName}
        actionDelete={props.deleteRecord}
        searchValue={props.search}
      />
    </ContainerComponent>
  )

}