import {Typography} from "@material-tailwind/react";
import {tableCustomStyles} from "./TableComponent";

export function NoDataComponent({columns}) {
    return (
        <div className="w-full min-w-max table-auto text-left py-0 ">
            <table className="w-full min-w-max table-auto text-left h-[35px]">
                <thead>
                <tr>
                    {columns.map((head) => (
                        <th
                            key={head.name}
                            style={{backgroundColor: tableCustomStyles.headCells.style.backgroundColor}}
                            className="border-b border-blue-gray-100 px-4 py-1"
                        >
                            <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-bold h-[20px]"
                            >
                                {head.name}
                            </Typography>
                        </th>
                    ))}
                </tr>
                </thead>
            </table>
            <div>
                <Typography variant="h6" className="text-center mt-2">
                    Sin datos
                </Typography>
            </div>
        </div>
    )
}