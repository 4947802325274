import React, {useState} from "react";
import {INITIAL_VALUE_PRICE, PRODUCT_WEIGHT} from "../../../utils/shared";
import {Button, IconButton, Tooltip, Typography} from "@material-tailwind/react";
import {PlusIcon} from "@heroicons/react/24/solid";
import {InputComponent} from "../../../components/elements/Inputs";
import {Field, FieldArray} from "formik";
import {SelectField} from "../../../components/elements/Inputs/SelectComponent";
import {ModalComponent} from "../../../components/elements/Modal/ModalComponent";
import FormPrices from "./FormPrices";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

const CUSTOM_ANIMATION = {
  mount: {scale: 1},
  unmount: {scale: 0.9},
};

function IconAccordion({open}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className={`${open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5"/>
    </svg>
  );
}

function FormPresentation({remove, push, isView, form}) {
  const [openModal, setOpenModal] = useState(false);
  const [accordionOpen, setAccordion] = useState(0);
  const [indexToRemove, setIndexToRemove] = useState(null);
  const [indexPriceToRemove, setIndexPriceToRemove] = useState(null);

  const handleAddPresentation = () => {
    push({
      name: '',
      description: '',
      unit: '',
      conversion: 1,
      prices: INITIAL_VALUE_PRICE
    });
  };

  const handleRemovePresentation = (index) => {
    setIndexToRemove(index);
    setOpenModal(true);
  };

  const confirmRemovePresentation = () => {
    remove(indexToRemove);
    setIndexToRemove(null);
    setOpenModal(false);
  };

  const handleAddPrice = (arrayHelpers) => {
    arrayHelpers.push({
      price_type: '',
      amount: 0,
      status: 1,
      product: null,
      productPresentation: null
    });
  };

  const handleRemovePrice = (index, arrayHelpers, indexPrice) => {
    setIndexToRemove(index);
    setIndexPriceToRemove(indexPrice);
    setOpenModal(true);
  };

  const confirmRemovePrice = () => {
    form.values.presentations[indexToRemove].prices.splice(Number(indexPriceToRemove), 1);
    setIndexToRemove(null);
    setIndexPriceToRemove(null);
    setOpenModal(false);
  };


  return (
    <>
      <div className="flex justify-between items-center mb-2">
        <Typography variant="h3" className="text-xl font-semibold">Presentaciones</Typography>
        <ButtonAddItem addAction={handleAddPresentation} isView={isView}/>
      </div>
      {form?.values?.presentations?.map((presentation, index) => (
        <Accordion
          open={accordionOpen === index}
          className=""
          key={index}
          icon={<IconAccordion open={accordionOpen === index}/>}
          animate={CUSTOM_ANIMATION}
        >
          <AccordionHeader
            className=" pb-0 pt-2 w-full"
            onClick={() => setAccordion(accordionOpen === index ? null : index)}>
            <Typography
              className={`w-full font-semibold text-blue-gray-800  transition-colors ${accordionOpen === index ? "text-blue-500 hover:!text-blue-700" : ""}`}
              variant="small">
              No. {index + 1}) {presentation.name}
            </Typography>
          </AccordionHeader>

          <AccordionBody className="pt-0 w-full border border-blue-gray-100">
            <div key={index} className="card  m-1 rounded-md p-4 space-y-4">
              <div className="grid grid-cols-1 lg:xl:2xl:grid-cols-2 gap-4">
                <Button
                    type="button"
                    className="xl:lg:2xl:md:hidden flex xl:lg:2xl:md:w-full justify-center items-center  -mt-2 bg-red-100 px-0 mb-2"
                    variant={'text'}
                    color="red"
                    disabled={((index === 0) ? true: isView)}
                    onClick={() => handleRemovePresentation(index)}
                >
                  <SecondTrashIcon className="w-4"/> Eliminar presentación {index + 1}
                </Button>
                <div className="space-y-2">
                  <InputComponent
                    name={`presentations[${index}].name`}
                    label="Nombre presentacion"
                    disabled={isView}
                  />
                </div>
                <div className="space-y-2 flex">
                  <div className="w-[85%]">
                    <Field
                      name={`presentations[${index}].unit`}
                      options={PRODUCT_WEIGHT}
                      component={SelectField}
                      label="Unidad de medida"
                      disabled={isView}
                    />
                  </div>
                  <div className={`hidden xl:lg:2xl:md:flex ${form.values.presentations.length > 1 ? isView ? 'hidden' : 'my-auto' : 'hidden'}`}>
                    <Tooltip
                      content={"Eliminar presentación " + (index + 1)}
                      placement="left"
                    >
                      <IconButton
                        variant="text"
                        className={'hover:text-red-900 hover:bg-red-100'}
                        onClick={() => handleRemovePresentation(index)} disabled={isView}>
                        <SecondTrashIcon className="w-4 4"/>
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              </div>
              <FieldArray
                name={`presentations[${index}].prices`}
                render={(arrayHelpers) => (
                  <FormPrices
                    presentation={presentation}
                    indexPresentation={index}
                    handleRemovePrice={handleRemovePrice}
                    arrayHelpers={arrayHelpers}
                    isView={isView}
                    handleAddPrice={handleAddPrice}
                  />
                )}
              />
            </div>
          </AccordionBody>
        </Accordion>
      ))}

      <ModalComponent
        show={openModal}
        setShow={setOpenModal}
        title={indexPriceToRemove !== null ? 'Eliminar precio' : 'Eliminar presentación'}
        description={indexPriceToRemove !== null ? '¿Está seguro que desea eliminar este precio?' : '¿Está seguro que desea eliminar esta presentación?'}
        labelButtonCancel="Cancelar"
        labelButtonConfirm="Eliminar"
        onConfirm={indexPriceToRemove !== null ? confirmRemovePrice : confirmRemovePresentation}
      />
    </>
  );
}

export function ButtonAddItem({addAction, isView}) {
  return (
    <>
      {
        !isView &&
        <Button
          className="flex items-center space-x-2 bg-secondary text-black"
          onClick={addAction}
          disabled={isView}
          color="blue-gray"
        >
          <PlusIcon className="h-4 w-4"/>
          <span>Añadir</span>
        </Button>
      }
    </>

  )
}

export function XIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M18 6 6 18"/>
      <path d="m6 6 12 12"/>
    </svg>
  )
}

export function SecondTrashIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M3 6h18"/>
      <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"/>
      <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"/>
    </svg>
  )
}


export default FormPresentation