import {required} from "../../../components/elements/Forms/validations";
import {FormComponent} from "../../../components/elements/Forms/FormComponent";
import {setDateInput} from "../../../utils/shared";
import api from '../../../js/api';
import {toastError, toastSuccess} from "../../../js/toast";


export function CreateCashRegister(props) {
  const {user} = props;

  const fields = {
    opening_date: {label: 'Fecha Apertura', type: 'date', disabled: true},
    user: {
      label: 'Usuario / Cajero',
      type: 'select',
      maxLength: '12',
      disabled: true,
      options: [{value: user?.id, label: user?.username}]
    },
    branch_office: {
      label: 'Sucursal',
      urlOptionList: 'branch-offices/select',
      type: 'select', validation: required(),
      disabled: !(user?.views === 'is_superuser')
    },
    initial_amount: {
      label: 'Saldo Inicial',
      validation: required().min(1, 'El campo debe tener mínimo 1 dígitos'),
      type: 'number',
      maxLength: '10'
    },
  }

  const initialState = {
    opening_date: setDateInput(new Date()),
    user: user?.id,
    branch_office: user?.branch_office,
    initial_amount: 0,
    status: 1,
  }

  const handleSubmit = (data) => {
    props.setLoader(true)
    api.post('cash-register', data)
      .then(() => {
        toastSuccess('¡Caja Aperturada!')
      })
      .catch((reason) => {
        if (reason?.response?.data?.detail) {
          toastError(reason?.response?.data?.detail)
        } else {
          toastError('¡Caja NO Aperturada!')
        }
      })
      .finally(() => {
        props.getMe(null);
        props.setLoader(false);
        props.setShow(false);
      });

  }

  return (
    <div className="shadow border p-4 w-full">
      <FormComponent
        isUpdate={false}
        isView={false}
        fields={fields}
        data={initialState}
        actionSubmit={handleSubmit}
        pathBack={'/'}
        divFormGroup={'!grid-cols-1'}
        textPrimaryButton={'Aperturar'}
      />
    </div>
  )
}