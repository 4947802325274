import {
  Card,
  CardBody,
  List,
  ListItem,
  Typography
} from '@material-tailwind/react';
import { setMoney, setDatetime } from '../../../utils/shared';
import {TITLES} from "../../../config/texts";
import {LoaderEllipsisComponent} from "../../../components/elements/Loader/LoaderComponent";
import React from "react";


export function BillFormat(props) {
  const { items, totalSales, componentRef, item } = props;

  return (
    <div ref={componentRef} className='w-auto mx-auto' id='saleReceipt'>
      <Card className='border w-auto mx-auto gap-0 rounded-none border-black/20'>
        <div className='pb-4 px-3 mt-5'>
          {/* ***************** HEADER BILL *****************  */}
          <div className="w-full text-center">
            <Typography variant='paragraph' color='black'>{TITLES.APP_NAME}</Typography>
            <Typography variant='small' color='black'>NIT: {TITLES.NIT_BILL}</Typography>
            <Typography variant='small' color='black'>{TITLES.ADDRESS_BILL}</Typography>
            <div className='border-b border-dashed border-black w-7/12 mx-auto'></div>
          </div>
          <div className="w-full text-center  mt-2">
            <Typography variant='small' color='black'>Recibo No: <strong>{item?.id}</strong></Typography>
            <Typography variant='small' color='black'>Fecha: {setDatetime(item?.creation_date)}</Typography>
            <Typography variant='small' color='black'>Atendio: {item?.cashier_person?.fullName}</Typography>
            <div className='border-b border-dashed border-black w-7/12 mx-auto'></div>
          </div>
        </div>
        <CardBody className='px-1'>
          <List className='px-0'>
            {/* ***************** HEADER PRODCUT *****************  */}
            <ListItem
              className="py-0 hover:bg-transparent cursor-default  rounded-none grid grid-cols-12 text-[14.5px] text-black product-select">

              <div className='col-span-1 border-b border-dashed border-black overflow-clip'>
                Cant.
              </div>
              <div className='col-span-6 border-b border-dashed border-black'>
                Descripcion
              </div>
              <div className='col-span-2 text-right '>
                <span className='border-b border-dashed border-black'>
                  Precio
                </span>
              </div>
              <div className='col-span-3 text-right '>
                <span className='border-b border-dashed border-black'>
                  Subtotal
                </span>
              </div>
            </ListItem>
            {/* ***************** PRODCUTS  *****************  */}
            {
              items.length ?
                items.map((item) => (
                  <ListItem
                    key={item.id}
                    className="py-0 px-0hover:bg-transparent gap-0 cursor-default rounded-none grid grid-cols-12 text-[12px] text-black product-select">
                    <div className='col-span-1'>
                      {item.quantity}
                    </div>
                    <div className='col-span-6'>
                      {item.fullName}
                    </div>
                    <div className='col-span-2 text-right'>
                      {setMoney(item.price * 1)}
                    </div>
                    <div className='col-span-3 text-right'>
                      {setMoney(item.price * item.quantity)}
                    </div>
                  </ListItem>
                ))
                : <div/>
            }
            {
              items.length === 0 && item.id !== undefined ?
              <div className='w-full text-center'>
                <Typography variant='paragraph' color='blue-gray'>No se encontro el detalle de productos</Typography>
              </div> : <div/>
            }
            <LoaderEllipsisComponent loader={item.sale_details === undefined}/>
            <ListItem
              className='pt-2 hover:bg-transparent cursor-default pb-1 rounded-none grid grid-cols-12 text-[14.5px] text-black product-select'>
              <strong className='col-end-13 col-span-5 text-black  text-right'>
                Total: {totalSales}
              </strong>
            </ListItem>
          </List>

        </CardBody>

      </Card>
    </div>

  )
}
