import React, {useEffect, useState} from "react";
import {
    Accordion,
    AccordionBody,
    AccordionHeader, Avatar,
    Button,
    Card,
    List,
    ListItem,
    ListItemPrefix,
    Typography,
} from "@material-tailwind/react";

import {ChartPieIcon, ChevronDownIcon, KeyIcon, PowerIcon, UserIcon, XMarkIcon,} from "@heroicons/react/24/solid";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import Menu from "./Menu";
import {ActionUserComponent} from "../ActionsUser";
import {Menu as MU} from 'lucide-react'
import {TITLES} from "../../../config/texts";

export function SidebarComponent(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const [openMenus, setOpenMenus] = useState({});
    const [didMount, setMount] = useState(false);
    const [menuData, setMenu] = useState([]);
    const {data} = props;

    useEffect(() => {
        setMount(true)
        renderViews()
    }, []);

  const nav = useNavigate();
  const logOutAction = async () => {
    props.logOut(nav)
  }

  const renderViews = () => {
        const views = localStorage.getItem('views')
        if (views === 'is_superuser') {
            setMenu(Menu)
        } else {
            const menu = [];
            const jsonViews = JSON.parse(views)
            // recorremos el array de vistas que tiene el usuario
            jsonViews?.forEach(({view_f}) => {
                // Recoremos el array de Menu para validar cuales se activan
                Menu.forEach((item) => {
                    // Si no tiene hijos se compara si los paths coinciden para agregarlo al nuevo menu

                    const split = view_f.split(',');

                    split.forEach((splitItem) => {

                        splitItem = splitItem.trim();
                        if (item._path === splitItem && !item.children) {
                            menu.push(item)
                        } else if (item.children) {
                            // Si tiene hijos se compara si los paths de los hijos coinciden con el item de la vista que se esta evaluando
                            // y si es asi se agrega a un nuevo array de hijos para este item
                            let child = []
                            item.children.forEach((ele) => {
                                if (ele._path === splitItem) {
                                    child.push(ele)
                                }
                            })
                            if (child.length) {
                                const newItem = {
                                    title: item.title,
                                    icon: item.icon,
                                    children: child,
                                }
                                const buttonChildren = menu.find((ele) => ele.title === newItem.title)
                                if (buttonChildren) {
                                    buttonChildren.children = [...buttonChildren.children, ...child]
                                } else {
                                    menu.push(newItem)
                                }
                            }
                        }
                    })
                })
            })
            setMenu(menu)
        }
    }

    useEffect(() => {
        renderViews()
    }, [didMount, data])

    const handleOpenMenu = (index) => {
        setOpenMenus(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    }

    const action = (url) => {
        if (url) navigate(url)
        hiddenSidebar()
    }
    const hiddenSidebar = () => {
        setIsMenuOpen(false);
    }

    let validateClassName = (item) => {
        const reg = /\/detalle\/+[0-9]/g;
        const reg2 = /\/crear/g;
        const reg3 = /\/editar\/+[0-9]/g;
        const reg4 = /\/lista/g;
        let rutaActual = location.pathname.replace(reg, '')
        rutaActual = rutaActual.replace(reg2, '')
        rutaActual = rutaActual.replace(reg3, '')
        rutaActual = rutaActual.replace(reg4, '')

        if (item?.children) {
            let classItemAcordion = 'p-0'
            item.children.forEach((child) => {
                if (child._path === rutaActual) {
                    classItemAcordion = 'p-0 bg-white bg-opacity-80 hover:bg-white active:bg-white'
                }
            })
            return classItemAcordion
        }
        return `${rutaActual === item._path ? 'bg-white text-selected-menu' : 'text-blue-gray-700'} focus:bg-white focus:text-selected-menu hover:bg-white hover:text-green-600 active:bg-white active:text-green-600`;
    };

  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

    return (
      <div className="lg:w-full ">
        <Card
          id="sidebarContainer"
          className="lg:max-w-[17rem] lg:w-full hidden lg:flex md:fixed lg:fixed shadow-transparent w-screen h-screen ms-4 my-4 bg-transparent">
          <Button type={"button"} onClick={hiddenSidebar}
                  className="lg:hidden md:hidden p-2 bg-transparent text-black" variant="filled">
            <XMarkIcon className="w-6 h-6"/>
          </Button>

          <Typography className="py-2 text-center" variant="h6" color="green">
            <Avatar
              alt="Usuario"
              src={'/assets/logoAgro.jpg'}
              className="w-9 h-9 ml-2"
            /> {TITLES.APP_NAME}
          </Typography>
          <ActionUserComponent {...props}/>

          <List
            className="shadow-blue-gray-900/5 shadow-lg overflow-auto mt-4 rounded-lg lg:h-[calc(100vh-15rem)] menu-container">
            {menuData.map((item, index) =>
              // Si item tiene children entonces se muestra el boton de abrir menu como un acordeon
              item.children ?
                <Accordion
                  key={index}
                  open={openMenus[index] ?? false}
                  icon={
                    <ChevronDownIcon
                      strokeWidth={2.5}
                      className={`mx-auto h-4 w-4 transition-transform ${openMenus[index] ? "rotate-180" : ""}`}
                    />
                  }>
                  <ListItem className={validateClassName(menuData[index])} selected={openMenus[index]}>
                    <AccordionHeader onClick={() => {
                      handleOpenMenu(index)
                    }} className="border-b-0 p-3">
                      <ListItemPrefix>
                        {item.icon}
                      </ListItemPrefix>
                      <Typography color="blue-gray" className="mr-auto font-normal">
                        {item.title}
                      </Typography>
                    </AccordionHeader>
                  </ListItem>
                  <AccordionBody className="py-1">
                    <List className="p-0">
                      {item.children.map((child, index) =>
                        <ListItem
                          key={index}
                          onClick={() => action(child._path)}
                          className={validateClassName(child)}>
                          <ListItemPrefix className="ml-4 mr-2"> {child.icon} </ListItemPrefix>
                          {child.title}
                        </ListItem>
                      )}
                    </List>
                  </AccordionBody>
                </Accordion>
                :
                <ListItem
                  key={index}
                  onClick={() => action(item._path)}
                  className={validateClassName(item)}>
                  <ListItemPrefix className="mr-2"> {item.icon} </ListItemPrefix>
                  {item.title}
                </ListItem>
            )}
          </List>
        </Card>

        <div className="lg:hidden z-10">
          <nav className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 px-4 py-2 z-20"
               style={{paddingBottom: '1.5rem'}}>
            <div className="flex justify-between items-center">

              <NavLink to="/" className="p-2 text-gray-600 hover:text-gray-900">
                <ChartPieIcon className="w-6 h-6"/>
              </NavLink>

              <NavLink to="/cambiar-credenciales" className="p-2 text-gray-600 hover:text-gray-900">
                <KeyIcon className="w-6 h-6"/>
              </NavLink>

              <NavLink to="/perfil" className="p-2 text-gray-600 hover:text-gray-900">
                <UserIcon className="w-6 h-6"/>
              </NavLink>

              <button
                className="p-2 text-gray-600 hover:text-gray-900"
                onClick={logOutAction}
              >
                <PowerIcon className="w-6 h-6"/>
              </button>

              <button
                className="p-2 text-gray-600 hover:text-gray-900"
                onClick={toggleMenu}
              >
                <MU className="w-6 h-6"/>
              </button>
            </div>
          </nav>
          <div
            className={`fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity duration-300 ease-in-out z-30 ${
              isMenuOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
            }`}
            onClick={toggleMenu}
          ></div>
          <div
            className={`fixed scroll-auto top-0 left-0 overflow-y-auto bottom-0 w-64 bg-white border-r border-gray-200 shadow-lg transform transition-transform duration-300 ease-in-out z-40 ${
              isMenuOpen ? 'translate-x-0' : '-translate-x-full'
            }`}
          >
            <Typography className="py-2 text-center mb-0" variant="h6" color="green">
              <Avatar
                alt="Usuario"
                src={'/assets/logoAgro.jpg'}
                className="w-9 h-9 ml-2"
              /> {TITLES.APP_NAME}
            </Typography>
            <List>
              {menuData.map((item, index) =>
                item.children ?
                  <Accordion
                    key={index}
                    open={openMenus[index] ?? false}
                    icon={
                      <ChevronDownIcon
                        strokeWidth={2.5}
                        className={`mx-auto h-4 w-4 transition-transform ${openMenus[index] ? "rotate-180" : ""}`}
                      />
                    }>
                    <ListItem className={validateClassName(menuData[index])} selected={openMenus[index]}>
                      <AccordionHeader onClick={() => {
                        handleOpenMenu(index)
                      }} className="border-b-0 p-3">
                        <ListItemPrefix>
                          {item.icon}
                        </ListItemPrefix>
                        <Typography color="blue-gray" className="mr-auto font-normal">
                          {item.title}
                        </Typography>
                      </AccordionHeader>
                    </ListItem>
                    <AccordionBody className="py-1">
                      <List className="p-0">
                        {item.children.map((child, index) =>
                          <ListItem
                            key={index}
                            onClick={() => action(child._path)}
                            className={validateClassName(child)}>
                            <ListItemPrefix className="ml-4 mr-2"> {child.icon} </ListItemPrefix>
                            {child.title}
                          </ListItem>
                        )}
                      </List>
                    </AccordionBody>
                  </Accordion>
                  :
                  <ListItem
                    key={index}
                    onClick={() => action(item._path)}
                    className={validateClassName(item)}>
                    <ListItemPrefix className="mr-2"> {item.icon} </ListItemPrefix>
                    {item.title}
                  </ListItem>
              )}
            </List>
          </div>
        </div>
      </div>
    );
}