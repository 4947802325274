import React from 'react';
import {ListItemPrefix, Typography} from "@material-tailwind/react";
import {KeyIcon, PowerIcon, UserCircleIcon} from "@heroicons/react/24/solid";
import {NavLink, useNavigate} from "react-router-dom";


export function ActionUserComponent(props) {
    let user = localStorage.getItem('user')
    if (user) {
        user = JSON.parse(user)
    }
    const nav = useNavigate()

    const showSideBar = () => {
        const sidebar = document.getElementById('sidebarContainer');
        sidebar.classList.remove('hidden')
        sidebar.classList.add('absolute')
        sidebar.classList.add('z-20')
        document.body.classList.add('overscroll-none')
        document.body.classList.add('overflow-hidden')
    }

    const logOutAction = async () => {
        props.logOut(nav)
    }


    return (
        <div className="bg-white flex flex-col justify-center items-center w-full p-3 rounded-lg shadow-md">
            <ListItemPrefix>
                <UserCircleIcon className="w-10 h-10"/>
            </ListItemPrefix>
            <Typography
                color="gray"
                variant="small"
                className="font-medium text-blue-gray-500"
            >
                {user?.first_name ? user?.first_name + ' ' + user?.last_name : user?.username ?? " "}
            </Typography>
            <div className="flex justify-between w-1/2">
                <NavLink to="/perfil" className="p-2 flex justify-center font-medium text-blue-gray-500 hover:bg-blue-200 rounded-lg">
                    <UserCircleIcon className="w-5 h-5"/>
                </NavLink>
                <NavLink to="/cambiar-credenciales" className="p-2 flex justify-center font-medium text-blue-gray-500 hover:bg-blue-200 rounded-lg">
                    <KeyIcon className="w-5 h-5"/>
                </NavLink>
                <div className="p-2 flex justify-center font-medium text-blue-gray-500 hover:bg-red-200 rounded-lg" onClick={logOutAction}>
                    <PowerIcon className="w-5 h-5"/>
                </div>
            </div>
        </div>
    );
}

export const toggleShowSidebar = () => {
    const sidebar = document.getElementById('sidebarContainer');
    const outlet = document.getElementById('outlet');
    const navbar = document.getElementById('navbar');
    sidebar.classList.toggle('!hidden')
    outlet.classList.toggle('lg:ml-[19rem]')
    outlet.classList.toggle('lg:w-[calc(100%-21rem)]')
    outlet.classList.toggle('max-h-[calc(100vh-3rem)]')
    navbar.classList.toggle('lg:w-[calc(100%-21rem)]')
    navbar.classList.toggle('px-6')
    navbar.classList.toggle('lg:ml-[19rem]')
    outlet.classList.toggle('w-auto')
    outlet.classList.toggle('px-6')
}