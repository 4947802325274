import {motion} from "framer-motion";
import {Card, CardBody} from "@material-tailwind/react";
import TitleCardContainer from "./Tables/TitleCardContainer";
import {connect} from "react-redux";
import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";

function ContainerComponent({title, uri, textBtnCreate, children, customButton, classNameMotionDiv='',...props}) {
  const [hasPermission, setPermission] = useState(false)
  const {user} = props;
  const location = useLocation();
  useEffect(() => {
    validatePermission()
  }, []);

  useEffect(() => {
    validatePermission()
  }, [user, location]);

  const validatePermission = ()=> {
    if(location){
      const views = localStorage.getItem('views')
      if(views === 'is_superuser'){
        setPermission(true)
      } else if(views){
        const jsonView = JSON.parse(views)
        jsonView.forEach((view)=>{
          if(location.pathname === view.view_f && view.create){
            setPermission(true)
          }
        })
      }
    }
  }


  return (
    <motion.div
      className={"h-full " + classNameMotionDiv}
      initial={{opacity: 0.2}}
      animate={{opacity: 1}}
      exit={{opacity: 0}}
      transition={{duration: 0.35}}
    >
      <Card className="py-5 h-full px-4 w-full border shadow-lg">
        <TitleCardContainer
            customButton={customButton}
            title={title}
            uri={hasPermission?uri:undefined}
            textBtnCreate={textBtnCreate}
        />
        <CardBody className="w-full px-0 lg:px-4 md:px-4 xl:p-2">
          {children}
        </CardBody>
      </Card>
    </motion.div>
  )

}
const states = (state) => {
  return {
    user: state.user.data,
  };
};

const ContainerComponentConn = connect(states, undefined)(ContainerComponent);
export default ContainerComponentConn;