import {required} from "../../../components/elements/Forms/validations";
import {FormComponent} from "../../../components/elements/Forms/FormComponent";
import {useNavigate} from "react-router-dom";
import {LoaderEllipsisComponent} from "../../../components/elements/Loader/LoaderComponent";
import {setDateInput} from "../../../utils/shared";
import {useEffect, useState} from "react";
import api from "../../../js/api";
import {toastError, toastSuccess} from "../../../js/toast";


export function CashRegisterClosing(props) {
  const {isUpdate = false, user} = props;
  const [didMount, setMount] = useState(false)
  const nav = useNavigate();

  useEffect(() => {
    setMount(true)
  }, []);

  const fields = {
    opening_date: {label: 'Fecha Apertura', type: 'date', disabled: true},
    closing_date: {label: 'Fecha Cierre', type: 'date', disabled: true},
    user: {
      label: 'Usuario / Cajero',
      type: 'select',
      maxLength: '12',
      disabled: true,
      options: [{value: user?.id, label: user?.username}]
    },
    branch_office: {
      label: 'Sucursal',
      urlOptionList: 'branch-offices/select',
      type: 'select',
      validation: required(),
      disabled: true
    },
    initial_amount: {
      label: 'Saldo Inicial',
      type: 'number',
      maxLength: '10',
      disabled: true
    },
    final_amount: {
      label: 'Saldo Final',
      validation: required().min(1, 'El campo debe tener mínimo 1 dígitos'),
      type: 'number',
      maxLength: '10'
    },
  }

  const initialState = {
    ...user?.cash_register,
    closing_date: setDateInput(new Date()),
    opening_date: user?.cash_register.opening_date ? setDateInput(new Date(user.cash_register.opening_date)) : null,
  }

  const handleSubmit = (data) => {
    props.setLoader(true);
    api.put(`cash-register/${data.id}`, data)
      .then(() => {
        toastSuccess('Caja Cerrada')
        nav('/');
      })
      .catch((reason) => {
        if (reason?.response?.data?.detail) {
          toastError(reason?.response?.data?.detail)
        } else {
          toastError('Error al cerrar la caja registradora')
        }
      })
      .finally(() => {
        props.getMe(null)
        props.setLoader(false);
        props.setShow(false)
      });
  }

  return (
    <>
      <div className="p-4 shadow">
        <LoaderEllipsisComponent loader={!user}/>
        {
          !!user?.cash_register &&
          <FormComponent
            isUpdate={isUpdate}
            isView={false}
            fields={fields}
            data={didMount ? initialState : {}}
            actionSubmit={handleSubmit}
            pathBack={'/'}
            customGoBack={()=>props.setShow(false)}
            divFormGroup={'!grid-cols-1'}
            textPrimaryButton={'Confirmar Cierre'}
          />
        }
      </div>
    </>
  )
}