import {Button, Card, Input, Typography,} from "@material-tailwind/react";
import {LoaderEllipsisComponent,} from "../../../components/elements/Loader/LoaderComponent";
import * as Yup from "yup";
import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {ErrorMessage, Form, Formik} from "formik";
import {required} from "../../../components/elements/Forms/validations";
import {TITLES} from "../../../config/texts";

function Login(props) {
  const nave = useNavigate();
  const {data, verifySession} = props;

  useEffect(() => {
    if (data) {
      verifySession(nave)
    }
  }, [data, verifySession])

  const SignInSchema = Yup.object().shape({
    password: required(),
    username: required(),
  });

  const actionSubmit = (values) => {
    props.loginAction(values, nave)
  }

  return (
    <div
      className="grid lg:grid-cols-9 2xl:grid-cols-8 md:grid-cols-8 xl:grid-cols-8 grid-cols-12 gap-2 h-[100vh] from-indigo-600 background-login">
      <div
        className="col-start-2 col-span-10 2xl:col-start-4 2xl:col-span-2 xl:col-start-4 xl:col-span-2 lg:col-start-4 lg:col-span-3 md:col-start-3 md:col-span-4 row-start-1 my-auto">
        <Card color="white"
              className="flex text-center xl:pt-14 lg:pt-14 md:pt-14 py-4 md:pb-10 lg:pb-10 xg:pb-10 shadow-2xl z-20 bg-opacity-95">
          <LoaderEllipsisComponent loader={props.loader}/>
          <img src="/assets/logoAgro.jpg" alt="Logo"
               className="w-32 h-32  mx-auto" alt=""/>
          <Typography variant="h4" color="blue-gray">
            {TITLES.APP_NAME}
          </Typography>
          <Typography color="gray" className="mt-1 text-sm w-10/12 mx-auto" variant="lead">
            Ingresa tus credenciales para poder iniciar sesión en la plataforma.
          </Typography>
          <Formik
            initialValues={{password: '', username: ''}}
            onSubmit={actionSubmit}
            validationSchema={SignInSchema}
          >
            {({
                errors,
                touched,
                handleChange,
                handleBlur,
              }) => (
              <Form
                className="my-8 mb-2 w-10/12 mx-auto text-left"
              >
                <div className="mb-1 flex flex-col gap-6">
                  <div className="w-full">
                    <Input
                      type="text"
                      variant='static'
                      label="Usuario"
                      name="username"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={!!(errors.username && touched.username)}
                    />
                    <div className="mt-1 flex justify-end items-end content-end px-2">
                      <ErrorMessage
                        name="username"
                        component="div"
                        className="ml-2 mt-1 text-red-500 text-[10px] font-normal"/>
                    </div>
                  </div>
                  <div className="w-full">
                    <Input
                      variant='static'
                      name="password"
                      type="password"
                      label="Contraseña"
                      autoComplete="false"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={!!(errors.password && touched.password)}
                    />
                    <div className="mt-1 flex justify-end items-end content-end px-2">
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="ml-2 mt-1 text-red-500 text-[10px] font-normal"/>
                    </div>

                  </div>
                </div>

                <Button
                  fullWidth
                  type="submit"
                  className="mt-6"
                >
                  Ingresar
                </Button>

                <div className="mt-8 text-end w-full">
                  <Typography color="gray" className="mt-2 text-end" variant="small">
                    Si olvidaste tus credenciales contacta al administrador para recuperarlas
                  </Typography>
                  {/*<a href="/resetear-contrasena" className="font-medium text-gray-900 text-sm">*/}
                  {/*  Restablecer contraseña*/}
                  {/*</a>*/}
                </div>
              </Form>
            )}
          </Formik>
        </Card>
      </div>
    </div>
  );
}

export default Login
