import {useEffect, useState} from "react";
import ContainerComponent from "../../../components/elements/ContainerComponent";
import {TableWithStripedRows} from "../../../components/elements/Tables/TableComponent";

export function InventoryList(props) {
  const [didMount, setDidMount] = useState(false);
  const {list} = props;
  console.log('DATA', props.data)

  useEffect(() => {
    setDidMount(true);
  }, []);
  
  useEffect(() => {
    if(didMount){
      list();
    }
  }, [didMount, list]);

  const columnsName = [
    {
      selector: row => row.id,
      name: 'Id',
    },
    {
      selector: row => row?.warehouse?.branch_office?.name,
      name: 'Sucursal',
    },
    {
      selector: row => row?.warehouse?.name,
      name: 'Bodega',
    },
    {
      selector: row => row?.product?.name + " (" +row?.presentation?.name + ")",
      name: 'Producto',
    },
    {
      selector: row => row.quantity,
      name: 'Existencia',
    },
    // {
    //   selector: row => row?.warehouse?.name,
    //   variant: 'boolean',
    //   name: 'Bodega',
    // },
  ]

  const Filters = [
    {
      placeholder: 'Sucursal',
      name_filter: 'branch_office_id',
      urlOptionList: 'branch-offices/select'
    },
    {
      placeholder: 'Bodega',
      name_filter: 'warehouse_id',
      urlOptionList: 'warehouse/options'
    }
  ]

  return (
    <ContainerComponent
      title="Inventario"
    >
      <TableWithStripedRows
        {...props}
        filters={Filters}
        changeFilter={props.changeFilterById}
        dontShowAction={true}
        columns={columnsName}
        actionDelete={props.deleteRecord}
        searchChange={props.searchChange}
        searchValue={props.search}
      />
    </ContainerComponent>
  )

}