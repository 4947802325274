import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {required} from "../../../components/elements/Forms/validations";
import {FormComponent} from "../../../components/elements/Forms/FormComponent";
import ContainerComponent from "../../../components/elements/ContainerComponent";


export function VentaCredito(props) {
  const {isView = false, isUpdate = false, item, retrieve} = props;
  const [didMount, setMount] = useState(false)
  const nav = useNavigate();
  const {id} = useParams();

  useEffect(() => {
    setMount(true)
  }, []);

  useEffect(() => {
    if (id && didMount) {
      retrieve(id);
    }
  }, [id, didMount, retrieve]);


  const fields = {
    nit: {label: 'NIT', maxLength: '13', type: 'number'},
    company_name: {label: 'Nombre de la Empresa', type: 'text', maxLength: '50', minLength: '3'},
    first_name: {label: 'Primer Nombre', validation: required()},
    second_name: {label: 'Segundo Nombre'},
    third_name: {label: 'Tercer Nombre'},
    last_name: {label: 'Primer Apellido', validation: required()},
    second_last_name: {label: 'Segundo Apellido'},
    department: {label: 'Departmaneto', type: 'select', urlOptionList: 'department/select_option'},
    municipality: {
      label: 'Municipio',
      type: 'select',
      urlOptionList: 'municipality/select_option',
      dependence: 'department'
    },
    address: {label: 'Dirección', validation: required()},
    phone_number: {label: 'Teléfono', type: 'number', maxLength: '8'},
    email: {label: 'Correo Electrónico', validation: required().email('Debe ingresar un correo valido')},
    birth_date: {
      type: 'date',
      label: 'Fecha de nacimiento',
      maxdate: new Date().setHours(new Date().getHours()),
      mindate: new Date().setMonth(new Date().getMonth() - (12 * 96)),
    },
    // branch_office: {label: 'Sucursal', validation: required(), type: 'select', urlOptionList: 'branch-offices/select'},
    // notification: {label: 'Enviar Notificaciones', type: 'checkbox'},
  }

  const handleSubmit = (dataForm) => {
    dataForm.person && delete dataForm.person;
    let data = {
      person: {
        ...dataForm
      },
      notification: dataForm.notification,
      branch_office: dataForm.branch_office,
    }
    delete data.person.notification;
    delete data.person.branch_office;
    if (id) {
      props.update(id, data, nav)
    } else {
      props.create(data, nav)
    }
  }
  return (
    <>
      <ContainerComponent
        title={isUpdate ? "Editar Venta al Credito" : isView ? `Venta al Credito ${id}` : "Crear Venta al Credito"}
      >
        <FormComponent
          isUpdate={isUpdate}
          isView={isView}
          fields={fields}
          data={item ? {...item, ...item.person} : {}}
          actionSubmit={handleSubmit}
          pathBack={'/venta-credito'}
        />
      </ContainerComponent>
    </>
  )
}