import {
  Dialog,
  DialogBody,
  Typography,
} from "@material-tailwind/react";
import React from "react";
import {CreateCashRegisterConn, CashRegisterClosingConn} from "../../AdminCashRegister";
import {LoaderEllipsisComponent} from "../../../components/elements/Loader/LoaderComponent";

export const ModalCashRegister = (
  {
    show,
    loader,
    setShow,
    closeCashRegister = false,
  }
) => {
  const handleOpen = () => setShow(!show);

  return (
    <>
      <Dialog open={show} handler={handleOpen} size="xs" dismiss={{outsidePress: false}}>
        <LoaderEllipsisComponent loader={loader}/>
        <DialogBody className="grid gap-2">
          <Typography color="blue-gray" variant="h4" className="text-center">
            {closeCashRegister? 'Cierre de caja': 'Apertura de caja'}
          </Typography>
          <Typography className="font-normal text-sm text-black">
            {closeCashRegister? '': 'Para poder realizar ventas se debe aperturar una caja registradora.'}
          </Typography>
          {closeCashRegister ?
            <CashRegisterClosingConn setShow={setShow}/>
            :
            <CreateCashRegisterConn setShow={setShow}/>
          }
        </DialogBody>
      </Dialog>
    </>
  )
}