import {connect} from 'react-redux';
import {CashRegisterList} from "./List/CashRegisterList";
import {CreateCashRegister} from "./Opening/CreateCashRegister";
import {CashRegisterClosing} from "./Closing/CashRegisterClosing";
import {actions} from '../../js/redux/cashRegister';
import {actions as userAction} from '../../js/redux/auth';

const cashRegisterState = (state) => {
  return {
    ...state.cashRegiter,
    user: state.user.data,
  };
};


const acciones = {...actions, getMe: userAction.verifySession}
const CreateCashRegisterConn = connect(cashRegisterState, acciones)(CreateCashRegister);
const CashRegisterClosingConn = connect(cashRegisterState, acciones)(CashRegisterClosing);
const CashRegisterListConn = connect(cashRegisterState, actions)(CashRegisterList);

export {
  CreateCashRegisterConn,
  CashRegisterListConn,
  CashRegisterClosingConn
}