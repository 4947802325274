import { Button, Typography } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import {BUTTON_CREATE} from "../../../config/componentsVariant";

export function CustomTitleCard({ title, className='' }) {
  return (
    <Typography className={"text-3xl font-bold" + className} >{title}</Typography>
  )

}

function TitleCardContainer({ title, uri, textBtnCreate, customButton=undefined }) {
  const navigate = useNavigate();

  const action = () => {
    if (uri) navigate(uri)
  }

  return (
    <div
      className="px-2 pt-2 pb-0 flex flex-col md:flex-row xl:flex-row lg:flex-row 2xl:flex-row justify-between gap-3">
      <CustomTitleCard title={title} className='shadow-' />
      {
        !['', undefined, null].includes(uri) &&
        <Button
          variant="filled"
          onClick={action}
          className="flex-row flex font-medium content-center items-center py-2 capitalize text-sm lg:mr-3 md:mr-3  mx-0 lg:xl:2xl:mx-3 hover:shadow-sm">
          <Typography color="inherit" variant="small" className={BUTTON_CREATE.CLASS_TEXT}>
            {textBtnCreate ? textBtnCreate : BUTTON_CREATE.DEFAULT_TEXT}
          </Typography>
        </Button>
      }
      {customButton}
    </div>)
}

export default TitleCardContainer;