import {useEffect, useState} from "react";
import ContainerComponent from "../../../components/elements/ContainerComponent";
import {TableWithStripedRows} from "../../../components/elements/Tables/TableComponent";
import {toastError} from "../../../js/toast";
import { useNavigate } from "react-router-dom";
import {ModalCashRegister} from "../../Sales/Create/ModalCashRegister";

export function VentasCreditoList(props) {
  const [didMount, setDidMount] = useState(false);
  const [openModalCashRegister, setOpenModalCashRegister] = useState(false);
  const [closeCashRegister, setCloseCashRegister] = useState(false);
  const {list} = props;
  const nav = useNavigate();

  useEffect(() => {
    setDidMount(true);
  }, []);

  useEffect(() => {
    if (didMount) StatusCaja();
  }, [didMount])

  useEffect(() => {
    if (didMount) {
      // list();
    }
  }, [didMount, list]);

  function StatusCaja(){
    let localUser = localStorage.getItem('user')
    if (localUser) {
      localUser = JSON.parse(localUser)
      if (!localUser?.cash_register) {
        setOpenModalCashRegister(true)
      }
    } else {
      nav('/')
    }

  }

  const columnsName = [
    {
      accessorKey: 'id',
      header: 'Id',
    },
    {
      accessorKey: 'branch_office_name',
      header: 'Sucursal',
    },
    {
      accessorKey: 'person.names',
      header: 'Nombres',
    },
    {
      accessorKey: 'person.last_names',
      header: 'Apellidos',
    },
    {
      accessorKey: 'person.phone_number',
      header: 'Teléfono',
    },
    {
      accessorKey: 'person.nit',
      header: 'NIT',
    },
  ]

  return (
    <ContainerComponent
      uri={'crear'}
      title="Ventas al Credito"
      textBtnCreate={'Crear Venta al Credito'}
    >
      <ModalCashRegister
        closeCashRegister={closeCashRegister}
        show={openModalCashRegister}
        loader={props.loaderCashRegister}
        setShow={setOpenModalCashRegister}
        title={"Aperturar caja Registradora"}
        message={"Para poder realizar ventas se debe aperturar una caja registradora."}
      />
      <TableWithStripedRows
        {...props}
        columns={columnsName}
        actionDelete={props.deleteRecord}
        searchValue={props.search}
      />
    </ContainerComponent>
  )

}