import {useState, useEffect, useRef} from 'react'
import {LoaderEllipsisComponent} from "../../../components/elements/Loader/LoaderComponent"
import {
  Card,
  CardBody,
  Input,
  List,
  ListItem,
  Tooltip,
} from '@material-tailwind/react';
import api from '../../../js/api';
import {colorStyles, SimpleSelect} from "../../../components/elements/Inputs/SelectComponent";
import {FieldArray, Form, Formik} from "formik";
import {PRICE_SALE} from "../../../utils/shared";
import SelectReact from 'react-select';
import {toastError, toastInfo} from "../../../js/toast";
import {
  InformationCircleIcon
} from "@heroicons/react/24/solid";


export function SearchProduct(props) {
  const [products, setProducts] = useState([]);
  const [warehouseOption, setWarehouseOption] = useState([]);
  const [warehouse, setWarehouse] = useState({});
  const [loaderWarehouse, setLoaderWarehouse] = useState(false);
  const [didMount, setMount] = useState(false);
  const [localLoader, setLocalLoader] = useState(false);
  const searchRef = useRef()

  useEffect(() => {
    setMount(true)
  }, [])

  useEffect(() => {
    if (didMount) {
      setLoaderWarehouse(true)
      api.get('warehouse/options')
        .then((response) => {
          // console.log('response.data', response.data)
          if(response.data?.length){
            setWarehouse(response.data[0])
            setWarehouseOption(response.data);
          } else {
            toastError('No se puede ralizar una venta si no existe una bodega')
          }
        })
        .catch(() => {
        })
        .finally(() => {
          setLoaderWarehouse(false)
        });
    }
  }, [didMount])

  useEffect(() => {
    if (didMount && warehouse.value) {
      let e = {target: {value: ''}}
      search(e)
    }
  }, [didMount, warehouse])

  const search = (e) => {
    const params = {
      search: e.target.value,
      warehouse_id: warehouse?.value
    }
    setLocalLoader(true)
    api.get('product-presentation', params)
      .then((response) => {
        setProducts(response.data.results);
      })
      .catch(() => {
      })
      .finally(() => {
        setLocalLoader(false)
      });
  }

  const addProduct = (element) => {
    const condition = element.price_type ?? PRICE_SALE
    const price = element.prices.find((price) => price.price_type === condition)?.amount ?? 0;
    if(!element.warehouse.quantity){
      toastInfo('Producto sin existencia')
      return;
    }

    props.addItem({
      id: element.id, // id de la presentación del producto
      name: element.name, // nombre del producto concatenado con su presentacion
      price_type: condition, // id del tipo de precio
      quantity: 1,
      product_id: element.product_id,
      price: price, // precio final seleccionado
      stock: element.warehouse.quantity, // existencia
      product_warehouse: element.warehouse.id, // inventario
      warehouse: element.warehouse.warehouse.id // bodega
    })
    searchRef.current.value = ''
  }


  return (
    <Card className='border rounded-none border-blue-gray-200'>
      <div className="px-4 pt-1">
        <div>
          <SelectReact
            isLoading={loaderWarehouse}
            value={warehouse}
            loadingMessage={()=><div>Sin datos</div>}
            onChange={setWarehouse}
            placeholder="Bodega"
            styles={colorStyles(false)}
            className="mt-2 mb-0"
            options={warehouseOption}
          />
        </div>
        <Input
          inputRef={searchRef}
          className="col !mt-[0.5px]"
          onChange={search}
          placeholder="Buscar producto..."
          type='text'
        />
      </div>
      <CardBody className="px-0">
        <FormProductPresentation
          products={products}
          addProduct={addProduct}
          loader={localLoader}
        />
      </CardBody>
    </Card>
  )

}


export const FormProductPresentation = ({products, addProduct, loader}) => (

  <div>
    {/*<div className="mb-0 pl-3 bg-blue-gray-50 font-bold text-black text-sm py-2">Resultados:</div>*/}
    <Formik
      enableReinitialize={true}
      initialValues={{products: products}}
      onSubmit={values => {
        console.log(values);
      }}
    >
      <Form>
        <FieldArray
          name="presentations"
          render={({form }) =>
            <div>
              <LoaderEllipsisComponent loader={loader}/>
              {/* CABECERA DE PRODUCTOS DISPONIBLES */}
              <List className='px-0 gap-0 py-0'>
                <ListItem
                  ripple={false}
                  className="hover:bg-blue-gray-50/100 border grid grid-cols-12 bg-blue-gray-50 opacity-100 pt-1 pb-1 rounded-none text-[13px] font-bold">
                  <div className='col-span-9 text-black'>
                    Producto
                  </div>
                  <div className='col-span-3 text-black'>
                    Precio
                  </div>
                </ListItem>
              </List>
              {/* ITEMS */}
              {
                form.values.products.map((item, index) => (
                  <ListItem
                    key={index}
                    ripple={false}
                    className="border pointer-none !pt-1 pb-0 w-full grid grid-cols-12 rounded-none text-[13px] hover:bg-blue-gray-50/30 focus:bg-transparent pr-5"
                  >
                    <div
                      onClick={() => addProduct(form.values.products[index])}
                      className="col-span-9 py-1 cursor-pointer flex flex-row">
                      {item?.name}
                      {
                        (item.warehouse?.quantity ===0) &&
                        <Tooltip content="Producto agotado" placement="top">
                        <div className="ml-3"><InformationCircleIcon className="w-5 h-5 text-blue-gray-200"/></div>
                        </Tooltip>
                      }
                    </div>
                    <div className="col-span-3">
                      <SimpleSelect
                        label=""
                        initialValue={PRICE_SALE}
                        isClearable={false}
                        name={`products[${index}].price_type`}
                        options={item.prices}
                      />
                    </div>
                  </ListItem>
                ))
              }
            </div>
          }
        />
      </Form>
    </Formik>
  </div>
);
