import {useState, useMemo, useEffect} from "react";
import {
  EyeIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/solid";
import {
  Card,
  IconButton,
  CardHeader,
  CardBody,
} from "@material-tailwind/react";
import {useNavigate} from "react-router-dom";
import {LoaderEllipsisComponent} from "../Loader/LoaderComponent";
import {DialogOfDeleteComponent} from "../Dialogs/DialogComponent";
import DataTable from 'react-data-table-component';
import {useLocation} from 'react-router-dom';
import {connect} from "react-redux";
import {ROWS_PER_PAGE} from "../../../utils/shared";
import {SecondTrashIcon} from "../../../modules/Products/Create/FormPresentation";
import {NoDataComponent} from "./NoDataComponent";
import {SearchInput} from "./SearchInput";
import {TableSearchAndFilter} from "./TableSearchAndFilter";

export const tableCustomStyles = {
  headCells: {
    style: {
      fontSize: '14px',
      fontWeight: 'bold',
      backgroundColor: 'rgba(225,225,225,0.4)',
      height: '35px',
    },
  },
}

function TableWithStripedRowsComponent({data = [], columns = [], page = 1, ...props}) {
  const {loader = false, actionDelete, user} = props;
  const [show, setShow] = useState(false);
  const [record, setItem] = useState(false);
  const [didMount, setMount] = useState(false);
  const [columnsFinal, setColumns] = useState(columns);
  const nav = useNavigate();
  const [hasPermission, setPermission] = useState(false)
  const [hasPermissionEdit, setPermissionEdit] = useState(false)
  const location = useLocation();
  const {results, count} = data

  useEffect(() => {
    setMount(true)
    validatePermission()
  }, [])

  useEffect(() => {
    validatePermission()
  }, [location, user, didMount]);

  const validatePermission = () => {
    if (location) {
      const views = localStorage.getItem('views')
      if (views === 'is_superuser') {
        setPermission(true)
        setPermissionEdit(true)
      } else {
        if (views) {
          const jsonViews = JSON.parse(views)
          jsonViews.forEach((ele) => {

            let split = ele.view_f.split(',');

            split.forEach((item) => {
              item = item.trim();
              if (item === location.pathname && ele.read) {
                setPermission(true)
              }
              if (item === location.pathname && ele.edit) {
                setPermissionEdit(true)
              }
            })
          })
        }
      }
    }
  }

  useEffect(() => {
    if (didMount && !props.dontShowAction) {
      const columnsWithAction = [
        ...columns,
        {
          selector: row => row.id,
          name: 'Acciones',
          cell: row => <div className="flex">
            <IconButton
              variant="text"
              className="py-0 my-0 text-black/60 hover:text-indigo-400 hover:bg-gray-100"
              onClick={() => nav(`${props.newRoute + 'detalle/' + row.id}`.replace('undefined', ''))}>
              <EyeIcon className="w-5 h-5 text-indigo-400"/>
            </IconButton>
            {hasPermissionEdit &&
              <>
                <IconButton
                  variant="text"
                  className="py-0 my-0 text-black/60 hover:text-amber-600 hover:bg-gray-100"
                  onClick={() => nav(`${props.newRoute + 'editar/' + row.id}`.replace('undefined', ''))}
                >
                  <PencilSquareIcon className="w-5 h-5 text-amber-600"/>
                </IconButton>
                <IconButton
                  onClick={() => openModalDelete(row)}
                  variant="text"
                  className="hover:text-red-500 text-black/60 py-0 my-0 flex justify-center items-center hover:bg-gray-100">
                  <SecondTrashIcon className="w-5 h-5 text-red-500">
                  </SecondTrashIcon>
                </IconButton>
              </>
            }
          </div>
        }
      ]
      setColumns(columnsWithAction)
    }
  }, [didMount, columns, nav, props.newRoute]);

  const openModalDelete = (item) => {
    setShow(true)
    setItem(item)
  }

  const confirmCallBack = () => {
    actionDelete && actionDelete(record.id)
  }

  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ',';
    const lineDelimiter = '\n';
    const keys = Object.keys(array[0]);

    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach(item => {
      let ctr = 0;
      keys.forEach(key => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

// Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
  function downloadCSV(array) {
    const link = document.createElement('a');
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = 'export.csv';

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute('href', encodeURI(csv));
    link.setAttribute('download', filename);
    link.click();
  }


  const actionsMemo = useMemo(() => <ExportButtonExcel onExport={() => downloadCSV(results)}/>, [downloadCSV, results]);

  const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: false,
    selectAllRowsItemText: 'Todos',
  };

  return (
    <div className={hasPermission ? props.className ?? "" : 'hidden'}>
      <DialogOfDeleteComponent
        show={show}
        setShow={setShow}
        confirmCallBack={confirmCallBack}
      />
      <Card
        className={"h-max w-full shadow-sm " + props.classNameCard}>
        <TableSearchAndFilter {...props}/>
        <CardBody className="px-2 md:lg:xl:2xl:px-4">
          <LoaderEllipsisComponent loader={loader}/>
          <DataTable
            pagination
            columns={columnsFinal}
            data={results}
            dense
            paginationPerPage={ROWS_PER_PAGE}
            paginationDefaultPage={1}
            persistTableHead={false}
            customStyles={tableCustomStyles}
            actions={actionsMemo}
            paginationServer={true}
            paginationTotalRows={count}
            onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
              if (didMount) {
                props.ChangeRowsPerPage(currentRowsPerPage)
              }
            }}
            onChangePage={(page, totalRows) => {
              if (didMount) props.list(page);
            }}
            noDataComponent={<NoDataComponent columns={columns}/>}
            paginationComponentOptions={paginationComponentOptions}
          />
        </CardBody>
      </Card>
    </div>

  );
}

const ExportButtonExcel = ({onExport}) => {
  return (
    <IconButton color="gray" onClick={e => onExport(e.target.value)}>
      <img alt="excel" src="/assets/excel.png" className="w-5 h-5 text-green-500"/>
    </IconButton>
  )
};

const states = (state) => {
  return {
    user: state.user.data,
  };
};
const TableWithStripedRows = connect(states, undefined)(TableWithStripedRowsComponent);
export {TableWithStripedRows}