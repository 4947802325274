import React, {useEffect, useState} from 'react';
import {ErrorMessage, useField} from 'formik';
import {Input, Textarea, Typography} from "@material-tailwind/react";
import 'react-day-picker/dist/style.css';
import {setMoney} from "../../../utils/shared";


const TextAreaComponent = ({label, helpText, ...props}) => {
  const [field, meta] = useField(props);
  const [didFocus, setDidFocus] = useState(false);
  const handleFocus = () => setDidFocus(true);

  return (
    <div className="flex w-100 flex-col items-end gap-2">
      <Textarea
        className="rounded-none"
        {...props}
        {...field}
        label={label}
        name={field.name}
        onFocus={handleFocus}
        value={field.value ? field.value : ''}
        error={meta.error && !!didFocus}
      />
    </div>
  );

}

export function InputComponent({label, helpText, ...props}) {
  const [field, meta, helpers] = useField(props);
  const [didFocus, setDidFocus] = useState(false);

  useEffect(() => {
    // funciona solo con numeros
    if(props.value?.length){
      helpers.setValue(props.value)
      helpers.setTouched(true)
    }
  }, [props.value]);
  const handleFocus = () => setDidFocus(true);

  const inputHandler = (e) => {
    const {value} = e.target;
    if (String(value).length >= props.maxLength) {
      e.preventDefault();
    }
  };

  return (
    <div className="flex w-100 flex-col gap-2 py-2">
      {props.type === 'textarea' ? (
          <TextAreaComponent
            {...props}
            label={label}
            helpText={helpText}
            field={field}
            meta={meta}
          />
        ) :
        (<Input
            variant='static'
            className=""
            size="md"
            {...props}
            {...field}
            label={label}
            name={field.name}
            onFocus={handleFocus}
            onKeyPress={inputHandler}
            autoComplete="new-password"
            value={field.value ? field.value : ''}
            autoFocus={props.autoFocus ?? false}
            error={meta.error && !!didFocus}
          />
        )
      }
      {helpText && <Typography variant="small" className="text-[12px] font-normal">{helpText}</Typography>}
      <div className="mt-1 flex justify-end items-end content-end px-2">
        <ErrorMessage name={field.name} component="div" className="text-red-500 text-[10px] font-normal "/>
      </div>
    </div>
  );
}

export function InputTable({label, helpText, type, ...props}) {
  const [field, meta] = useField(props);
  const {error, touched} = meta;

  const inputHandler = (e) => {
    const {value} = e.target;
    if (String(value).length >= props.maxLength) {
      e.preventDefault();
    }
  };

  function numberOnly() {
    let element = document.getElementById(props.name);
    const regex = /^\d*\.?\d*$/;
    if (type === 'current' && regex.test(element.value)) {
    } else {
      element.value = element.value.replace(/\D/g, '');
    }
  }

  return (
    <div className="w-[80px] h-[100%]">
      <input
        id={props.name}
        className={`custom-input text-sm py-0 w-[100%] ${(error && touched) ? "!border-red-500 border" : ""}`}
        {...props}
        {...field}
        // type="number"
        // min="-1"
        // max="9999"
        onInput={numberOnly}
        name={field.name}
        onKeyPress={inputHandler}
        autoComplete="new-password"
        value={field.value ? field.value : ''}
        autoFocus={props.autoFocus ?? false}
      />
      {/*{meta.error && meta.touched && (*/}
      {/*  <div className="error">Requerido</div>*/}
      {/*)}*/}
    </div>
  );
}

