import {
  Card,
  CardBody,
  IconButton,
  Input,
  List,
  ListItem,
  Typography
} from '@material-tailwind/react';
import { setMoney } from '../../../utils/shared'
import { TrashIcon } from '@heroicons/react/24/solid';
import {toastInfo} from "../../../js/toast";

export function ProductSelectedComponent(props) {
  const { items, removeItem, refNitInput, totalSales, isBill = false } = props;

  return (
    <Card className='border rounded-none border-blue-gray-200'>
      <div className='pb-4 border-b border-gray-400/50 px-3 mt-5'>
        <Input
          label='NIT Cliente'
          type='text'
          inputRef={refNitInput}
          placeholder='C/F'
        />
      </div>
      <CardBody className='px-2'>
        <List className='px-0'>
          {/* ***************** HEADER  *****************  */}
          <ListItem
            ripple={false}
            className="hover:bg-blue-gray-50/100 border md:xl:lg:2xl:grid md:xl:lg:2xl:grid-cols-12 bg-blue-gray-50 opacity-100  pt-1 pb-1 hidden rounded-none text-[13px] font-bold">
            <div className='col-span-2 ml-0 underline text-black'>
              Cant.
            </div>
            <div className='col-span-5 text-justify text-black'>
              Producto
            </div>
            <div className='col-span-2 text-right text-black'>
              Precio
            </div>
            <div className='col-span-2 text-right text-black'>
              Subtotal
            </div>
            <div className='col-span-1 text-right text-black'>
            </div>
          </ListItem>
          {/* ***************** ITEMS  *****************  */}
          {
            items.length ? items.map((item, index) => (
              <ListItem
                ripple={false}
                key={index}
                className="pt-0 hover:bg-transparent cursor-default px-1 gap-1 pb-1 border-b border-gray-500 rounded-none grid grid-cols-12 text-[12px] text-black product-select">
                <div className='col-span-12 flex lg:col-span-2'>
                  <span className='lg:md:2xl:xl:hidden text-sm font-bold w-60'>Cantidad: </span>
                  <input
                    type='number'
                    disabled={isBill}
                    value={item.quantity > 0 ? item.quantity : ""}
                    className='disabled:bg-transparent lg:xl:2xl:w-20 py-0 text-end lg:text-left px-2'
                    onChange={(e) => {
                      const value = parseInt(e.target.value, 10);
                      const stock = item.stock;
                      if (!isNaN(value) && value >= 0) {
                        if (value <= stock) {
                          props.changeValueItemSelected(item, value);
                        } else {
                          toastInfo(`Solo existen ${stock} de ${item.name} disponibles`);
                        }
                      }
                    }}/>
                </div>
                <div className='col-span-12 lg:xl:2xl:col-span-5 flex text-justify justify-between'>
                  <span className='block lg:md:2xl:xl:hidden text-sm font-bold'>Producto: </span>
                  {item.name}
                </div>
                <div className='col-span-12 flex justify-between lg:md:2xl:xl:col-span-2 text-right lg:md:2xl:xl:justify-end'>
                  <span className='block lg:md:2xl:xl:hidden text-sm font-bold'>Subtotal: </span>
                  {setMoney(item.price * 1)}
                </div>
                <div className='col-span-12 flex justify-between lg:md:2xl:xl:col-span-2 text-right  lg:md:2xl:xl:justify-end'>
                  <span className='block lg:md:2xl:xl:hidden text-sm font-bold'>Total: </span>
                  {setMoney(item.price * item.quantity)}
                </div>

                <div className='col-span-12 lg:col-span-1 md:col-span-1 2xl:col-span-1 xl:col-span-1 text-right'>
                  <IconButton className='icon-del-item h-6 bg-transparent' onClick={() => removeItem(item.id)}>
                    <TrashIcon className='w-5 h-5 text-deep-orange-500' />
                  </IconButton>
                </div>
              </ListItem>
            ))
              :
              <div className='w-full text-center'>
                <Typography variant='paragraph' color='amber'>Aun no has ingresado productos</Typography>
              </div>
          }
        </List>
        <p className='w-full grid-cols-12 lg:md:xl:2xl:grid lg:md:xl:2xl:text-right text-right'>
          <strong className='lg:md:xl:2xl:col-span-3 lg:md:xl:2xl:col-start-8 lg:md:xl:2xl:col-end-12 text-right text-black'>
            Total: {totalSales}
          </strong>
        </p>
      </CardBody>

    </Card>
  )

}
