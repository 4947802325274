import React, {useState} from "react";
import {Field} from "formik";
import {Button, IconButton, Tooltip, Typography} from "@material-tailwind/react";
import {PRICE_SALE, PRICE_TYPE} from "../../../utils/shared";
import {InputComponent} from "../../../components/elements/Inputs";
import {SelectField} from "../../../components/elements/Inputs/SelectComponent";
import {ButtonAddItem, SecondTrashIcon} from "./FormPresentation";


function FormPrices({indexPresentation, presentation, isView, handleRemovePrice, arrayHelpers, handleAddPrice}) {
  const [optionPrices, setOptionPrices] = useState(PRICE_TYPE);
  const setOptionAsSelected = (itemSelect) => {
    const optionSelected = [itemSelect]
    for (const priceIndex in presentation.prices) {
      if (!["", undefined, null, 0].includes(presentation.prices[priceIndex].price_type)) {
        optionSelected.push(presentation.prices[priceIndex].price_type)
      }
    }
    const newOptions = optionPrices.map(item => {
      item.disabled = optionSelected.includes(item.value)
      return item
    })
    setOptionPrices(newOptions)
  }

  return (
    <div className="!mt-[-2px]">
      <div className="flex justify-between items-center pb-2">
        <Typography variant="h4" className="text-lg font-semibold">
          Precios
        </Typography>
        <ButtonAddItem
          addAction={() => handleAddPrice(arrayHelpers)}
          isView={isView || !(presentation?.prices?.length < PRICE_TYPE.length)}
        />
      </div>
      {presentation.prices.map((price, indexPrice) => (
        <div key={indexPrice} className="space-y-4 pb-2">
          <div className="grid grid-cols-1 xl:lg:2xl:md:grid-cols-3 gap-2 xl:lg:2xl:md:gap-4 items-center border-b">
            <div className="space-y-2">
              <Field
                defaultOptions={optionPrices}
                options={optionPrices}
                callbackOnChange={setOptionAsSelected}
                component={SelectField}
                menuPlacement="top"
                name={`presentations[${indexPresentation}].prices[${indexPrice}].price_type`}
                label="Tipo de precio"
                disabled={isView || (presentation.prices[indexPrice].price_type === PRICE_SALE)}
              />
            </div>
            <div className="space-y-2 col">
              <InputComponent
                name={`presentations[${indexPresentation}].prices[${indexPrice}].amount`}
                label="Monto"
                type="number"
                disabled={isView}
              />
            </div>
            <Tooltip content="Eliminar precio" placement="left" className={"xl:lg:2xl:md:space-x-2 xl:lg:2xl:md:col xl:lg:2xl:md:block hidden"}>
              <IconButton
                color="red"
                className={`w-8 h-8 my-auto xl:lg:2xl:md:block hidden rounded-none ${(presentation.prices[indexPrice].price_type === PRICE_SALE) && 'hidden'} ${isView && 'hidden'}`}
                onClick={() => handleRemovePrice(indexPresentation, arrayHelpers, indexPrice)}
                disabled={isView}>
                <SecondTrashIcon className="w-4 4"/>
              </IconButton>
            </Tooltip>
            <Button
              type="button"
              className="lg:hidden flex justify-center items-center w-full -mt-3 bg-red-50"
              variant={'text'}
              color="red"
              onClick={() => handleRemovePrice(indexPresentation, arrayHelpers, indexPrice)}
            >
              <SecondTrashIcon className="w-4"/> Eliminar precio {indexPrice +1}
            </Button>
          </div>
        </div>
      ))}
    </div>

  );
}


export default FormPrices