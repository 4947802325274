import {useEffect, useState} from "react";
import {Option, Select} from "@material-tailwind/react";
import SelectReact from 'react-select';
import {Field, useField, useFormikContext} from "formik";
import api from "../../../js/api";
import {setMoney} from "../../../utils/shared";
import AsyncSelect from "react-select/async";

export function SelectComponent(props) {
  return (
    <Field
      {...props}
      component={SelectField}
    />
  )
}

export function SimpleSelect({options, onChange = undefined, ...props}) {
  const [field, meta, helper] = useField(props.name);

  return (
    <div className="flex !min-w-[100px] !max-w-[100px] flex-col gap-0 p-0 rounded-none">
      <Select
        size="md"
        // onChange={(value) => {
        //   onChange && onChange(value)
        // }}
        {...field}
        name={field.name}
        value={field.value ? field.value : props.initialValue}
        inputMode="text"
        // value={fieldProp.value ?? initialValue}
        variant="outlined"
        onChange={(value) => {
          helper.setValue(value)
        }}
        onBlur={field.onBlur}
        labelProps={{className: 'hidden'}}
        className="!p-0 rounded-[0px] text-[12px] hover-select  !content-none min-w-[100px] max-w-[100px] hidden-svg"
        menuProps={{className: 'p-1 border-none rounded-[0px] '}}
        containerProps={{className: 'h-6 !min-w-[100px]  border-none !max-w-[100px] !content-none'}}
      >
        {options?.map(({amount, price_type}, index) =>
          <Option key={index} className="text-[12px] py-1" value={`${price_type}`}>{setMoney(amount)}</Option>
        )}
      </Select>
    </div>
  )
}

export const SelectField = ({options = [], urlOptionList, field, dependence, isClearable = true, ...props}) => {
  const {
    defaultOptions,
    loading,
    hideError=false,
    classContainer = 'mt-2',
    classSubContainer = "flex w-100 flex-col gap-2 py-2  lg:-mt-[8px] md:-mt-[8px] xl:-mt-[8px] 2xl:-mt-[2px] mb-2.5"
  } = props
  const {values, setFieldValue, getFieldMeta} = useFormikContext();
  const [didMount, setMount] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [didFocus, setDidFocus] = useState(false);
  const [optionsList, setOptions] = useState([]);
  const [dependenceValue, setDependence] = useState();
  const {value, name} = field;
  const hasError = didMount && didFocus && !value && getFieldMeta(name)?.error;

  useEffect(() => {
    if (options.length) {
      setOptions(options)
    }
  }, [options])

  useEffect(() => {
    setMount(true)
  }, []);

  useEffect(() => {
    if (didMount && !dependence && urlOptionList) {
      setIsLoading(true)
      api.get(urlOptionList)
        .then((res) => {
          if (res.data) {
            setOptions(res.data)
          }
        })
        .catch((err) => {
        })
        .finally(() => setIsLoading(false))
    }

  }, [urlOptionList, didMount, dependence]);

  useEffect(()=>{
    if(dependence && values) {
      setDependence(values[dependence])
    }
  }, [dependence, values])

  useEffect(() => {
    if (didMount && dependence && dependenceValue) {
      api.get(urlOptionList + "/?id=" + dependenceValue)
        .then((res) => {
          if (res.data) {
            setOptions(res.data)
          }
        })
        .catch((err) => {
        })
    }
  }, [urlOptionList, dependence, didMount, dependenceValue]);

  const handleFocus = () => {
    setDidFocus(true)
  };

  const callbackOnChange = async (optionSelected) => {
    await setFieldValue(field.name, optionSelected?.value);
    props?.callbackOnChange && props?.callbackOnChange(optionSelected?.value);
  }

  return (
    <div className="container-form-input min-w-[80px] z-auto" style={{flexBasis: '8px'}}>
      <div className={classSubContainer}>
        <div className="relative w-full min-w-[200px] h-10 pb-1 ">
          <SelectReact
            onMenuOpen={handleFocus}
            className={classContainer}
            isLoading={isLoading ?? loading}
            loadingMessage={() => <div>Cargando datos</div>}
            defaultOptions={defaultOptions ?? []}
            noOptionsMessage={() => <NoOptionsMessage/>}
            isClearable={isClearable}
            isDisabled={props.disabled}
            menuPlacement={props.menuPlacement ?? 'bottom'}
            styles={colorStyles(hasError)}
            placeholder={props.placeholder ?? ''}
            options={optionsList}
            name={field.name}
            isOptionDisabled={(option) => option.disabled}
            value={optionsList ? optionsList.find(option => option.value === field.value) : ''}
            onChange={callbackOnChange}
            onBlur={field.onBlur}
            onFocus={() => {
              handleFocus();
            }}
          />
          <label
            className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-3 text-sm peer-focus:text-sm after:content[' '] after:block after:w-11/12 after:absolute after:-bottom-4 left-0 after:border-none after:scale-x-0 peer-focus:after:scale-x-100 after:transition-transform after:duration-300 !text-black/90 peer-disabled:!text-blue-gray-500 peer-placeholder-shown:leading-tight text-blue-gray-500 peer-focus:text-gray-500 after:border-gray-500 peer-focus:after:border-gray-500"
          >{props.label}
          </label>
        </div>
      </div>
      <div className={`mt-1 flex justify-end items-end content-end px-2`}>
        {(hasError && !hideError) &&
          <p className="text-red-500 text-[10px] font-normal">{getFieldMeta(name)?.error}</p>
        }
      </div>
    </div>
  )
}

// Componente de campo personalizado para integrar AsyncSelect con Formik
export const AsyncSelectField = ({field, form, loadOptions, ...props}) => {
  const [didMount, setMount] = useState(false)
  const [didFocus, setFocus] = useState(false)
  const {getFieldMeta} = useFormikContext();

  useEffect(() => {
    setMount(true)
  }, []);

  const handleChange = value => {
    form.setFieldValue(field.name, value);
  };

  const hasError = didMount && didFocus && !field?.value && getFieldMeta(field.name)?.error

  return (
    <div className="min-w-[100px] container-form-input" style={{flexBasis: '8px'}}>
      <div className="flex w-100 flex-col ">
        <div className="relative w-full min-w-[200px] h-10">
          <AsyncSelect
            {...field}
            {...props}
            placeholder={props.placeholder ?? ''}
            className="mt-[0.5px]"
            loadOptions={async (inputValue) => loadOptions(inputValue, didMount)}
            onChange={handleChange}
            value={field.value}
            cacheOptions
            onFocus={() =>
              setFocus(true)
            }
            loadingMessage={() => <div>Cargando datos</div>}
            noOptionsMessage={() => <NoOptionsMessage/>}
            styles={colorStyles(hasError)}
            defaultOptions
          />
        </div>
      </div>
      {/*<div className={`mt-0 flex justify-end items-end content-end px-2`}>*/}
      {/*  {hasError &&*/}
      {/*    <p className="text-red-500 text-[10px] font-normal">{getFieldMeta(field.name)?.error}</p>*/}
      {/*  }*/}
      {/*</div>*/}
    </div>
  );

}

const NoOptionsMessage = props => {
  return (
    <div>
      <span className="text-blue-gray-200 text-sm">Sin opciones</span>
    </div>
  );
};

export const colorStyles = (hasError) => {
  return {
    control: (baseStyles, state) => {
      return {
        ...baseStyles,
        backgroundColor: state.isDisabled ? '#eceff1' : '',
        borderRadius: 0,
        borderWidth: state.isDisabled ? '0px' : '1px',
        borderColor: hasError && !state.isFocused ? 'red' : '#CFD8DC',
        fontSize: '0.875rem',
        boxShadow: '0 !important',
        '&:hover': {
          border: hasError && !state.isFocused ? '1px solid red !important' : '1px solid #b0bec5 !important'
        },
        ':active': {
          ...baseStyles[':active'],
          borderColor: '#b0bec5'
        },
      }
    },
    option: (styles, {data, isDisabled, isFocused, isSelected}) => {
      return {
        ...styles,
        fontSize: "0.875rem",
        color: '#64748b',
        "backgroundColor": isSelected ? '#e2e8f0' : "rgba(255,255,255)",
        padding: "5px 15px",
        ':hover': {
          backgroundColor: '#f1f5f9',
          // color: '#94a3b8',
        },
        cursor: isDisabled ? 'not-allowed' : 'pointer',
        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
              ? '#CFD8DC'
              : '#CFD8DC'
            : undefined,
        },
      };
    },
    menu: (baseStyles, state) => {
      return {
        ...baseStyles,
        "backgroundColor": "rgba(253,253,253)",
        borderRadius: '0px',
        marginTop: '1px',
        padding: "0 0 10px 0px",
      }
    },
    placeholder: (styles, state) => ({
      ...styles,
      fontSize: '0.84rem',
      fontWeight: '300',
      fontFamily: 'Roboto, sans-serif !important',
      color: 'rgba(141,141,141,0.9)',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: '#455a64',
    }),
  }
};

// MultiSelect Simple Component
export const MultiSelectComponent = (props) => {
  const {
    urlOptionList = '',
    placeholder = '',
    options=[],
    dependence= undefined,
  } = props;
  const [didMount, setMount] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [optionsList, setOptions] = useState(options);
  useEffect(() => {
    setMount(true)
  }, []);

  useEffect(() => {
    if (didMount && urlOptionList && dependence === undefined) {
      setIsLoading(true)
      api.get(urlOptionList)
        .then((res) => {
          if (res.data) {
            setOptions(res.data)
          }
        })
        .catch((err) => {
        })
        .finally(() => setIsLoading(false))
    }

  }, [urlOptionList, didMount, dependence]);

  useEffect(() => {
    // Se ejecuta si depende del valor de otro campo y se especifique como una dependencia
    if (didMount && urlOptionList && ![undefined, null, ""].includes(dependence)) {
      setIsLoading(true)
      api.get(urlOptionList + "/?id=" + dependence)
        .then((res) => {
          if (res.data) {
            setOptions(res.data)
          }
        })
        .catch((err) => {
        })
        .finally(() => setIsLoading(false))
    }

  }, [urlOptionList, didMount, dependence]);

  return (
    <div className="w-full col-span-1">
      <SelectReact
        isClearable
        defaultValue={[]}
        onChange={props.onChange}
        isMulti={props.isMulti}
        isLoading={isLoading}
        placeholder={placeholder}
        styles={colorStyles(false)}
        options={optionsList}
        className="basic-multi-select"
        classNamePrefix="select"
      />
      <div className="absolute font-normal text-sm !mt-[-58px] text-black">
        {placeholder}
      </div>
    </div>

  )
}
