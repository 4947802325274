import React from "react";
import {Form, FieldArray} from 'formik';
import {InputComponent} from "../../../components/elements/Inputs";
import {ViewPermissionComponent} from './ViewPermissionCompoent'
import {SecondaryButton, PrimaryButton} from "../../../components/elements/Inputs/ButtonsComponents";

export const FormRol = ({screens, isValid, isUpdate, isView, goBack, loading, values}) => {

  return (
    <Form className="h-full flex flex-col justify-between gap-2 mt-2">
      <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 md:grid-cols-2 2xl:grid-cols-3 gap-5 mb-0">
        <InputComponent name='name' label='Nombre de rol' disabled={isView}/>
      </div>
      <div className="">
        <FieldArray
          name="views"
        >
          {(propsFieldArray) => (
            <ViewPermissionComponent
              {...propsFieldArray}
              values={values}
              options={screens}
              isView={isView}
              loading={loading}
            />
          )}
        </FieldArray>
      </div>
      <div className="flex md:flex-row lg:flex-row flex-col justify-between gap-3 mt-8">
        <SecondaryButton
          type="button"
          text={'Regresar'}
          onClick={goBack}
        >
        </SecondaryButton>
        {
          !isView &&
          <PrimaryButton
            text={isUpdate ? 'Actualizar' : 'Crear'}
            disabled={!isValid}
          >
          </PrimaryButton>
        }
      </div>
    </Form>
  )
}