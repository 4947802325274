import { toast } from 'react-toastify';

const _optionToas = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
  theme: "colored",
};

let activeToastId = null;

export const toastSuccess = (data) => {
  if (activeToastId && toast.isActive(activeToastId)) {
    return;
  }
  const message = typeof data === 'object' && data?.status ? validateStatus(data) : data;
  activeToastId = toast.success(message, _optionToas);
};

export function toastError(data) {
  if (activeToastId && toast.isActive(activeToastId)) {
    return;
  }
  let message = '';
  if (typeof data === 'object') {
    if (data.message && data.message.includes('timeout')) {
      message = 'El servidor tardo en responder';
    } else if (data.response) {
      message = validateStatus(data.response);
    }
  } else {
    message = data;
  }
  activeToastId = toast.error(message, _optionToas);
}

export function toastInfo(message) {
  if (activeToastId && toast.isActive(activeToastId)) {
    return;
  }
  activeToastId = toast.info(message, _optionToas);
}

function validateStatus(response) {
  let finalMessage = '';
  switch (response.status) {
    case 200:
      finalMessage = 'Acción realizada exitosamente';
      break;
    case 201:
      finalMessage = 'Registro creado exitosamente';
      break;
    case 400:
      finalMessage = response.data.detail ?? 'Ruta no encontrada';
      break;
    case 401:
      finalMessage = response.data.detail ?? 'Ruta no autorizada';
      break;
    case 402:
      finalMessage = 'Requiere pago';
      break;
    case 403:
      finalMessage = response.data.detail ?? 'Credenciales vencidas o invalidas';
      break;
    case 405:
      finalMessage = 'Metodo no encontrado';
      break;
    case 500:
      finalMessage = 'Error en el servidor, intenta más tarde';
      break;
    default:
      break;
  }
  return finalMessage;
}