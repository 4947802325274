import {Card, Typography} from "@material-tailwind/react";
import {setMoney} from "../../utils/shared";

const TABLE_HEAD = ["No. venta", "Cliente", "Fecha", "Total"];

const TABLE_ROWS = [
  {
    name: "John Michael",
    id: "001",
    date: "23/04/18",
    total: "100",
  },
  {
    name: "Alexa Liras",
    id: "002",
    date: "23/04/18",
    total: "110",
  },
  {
    name: "CF",
    id: "003",
    date: "19/09/17",
    total: "15",
  },
  {
    name: "CF",
    id: "004",
    date: "24/12/08",
    total: "300",
  },
  {
    name: "Richard Gran",
    id: "005",
    date: "04/10/21",
    total: "50",
  },
];

export function RecentOrders() {
  return (
    <Card className="h-full w-full rounded-lg p-4" style={{flexBasis: '150px !important'}}>
      <div>
        <Typography
          variant="h4"
          color="black"
          className="mb-1"
        >
          Ventas recientes
        </Typography>
        <Typography
          variant="small"
          color="black"
          className="leading-none opacity-70 mb-6 pl-1"
        >
          Las ventas ultimas 5 ventas de este mes.
        </Typography>
      </div>
      <div className="w-full overflow-x-scroll">
        <table className="w-full min-w-max table-auto text-left ">
          <thead>
          <tr>
            {TABLE_HEAD.map((head, index) => {
              const isLast = index === TABLE_HEAD.length - 1;
              const classes = isLast ? "text-center" : "";

              return (
                  <th
                      key={head}
                      className={`border-b border-blue-gray-100 px-4 py-3`}
                  >
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className={`font-normal leading-none opacity-70 ${classes}`}
                    >
                      {head}
                    </Typography>
                  </th>
              )
            })
            }
          </tr>
          </thead>
          <tbody>
          {TABLE_ROWS.map(({name, id, date, total}, index) => {
            const isLast = index === TABLE_ROWS.length - 1;
            const classes = isLast ? "px-4 py-2" : "px-4 py-1 border-b border-blue-gray-50";

            return (
                <tr key={name+index}>
                  <td className={classes}>
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                    >
                      {id}
                    </Typography>
                  </td>
                  <td className={classes}>
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                    >
                      {name}
                    </Typography>
                  </td>
                  <td className={classes}>
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                    >
                      {date}
                    </Typography>
                  </td>
                  <td className={classes}>
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-black text-right pr-6"
                    >
                      {setMoney(total)}
                    </Typography>
                  </td>
                </tr>
            );
          })}
          </tbody>
        </table>
      </div>
    </Card>
  );
}