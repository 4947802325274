import * as Yup from "yup";
import {Formik} from "formik";
import React, {useEffect, useState} from "react";
import FormMainProduct from "./FormMainProduct";
import {useNavigate, useParams} from "react-router-dom";
import ContainerComponent from "../../../components/elements/ContainerComponent";
import {LoaderEllipsisComponent} from "../../../components/elements/Loader/LoaderComponent";
import {
  INITIAL_VALUE_PRICE,
  INITIAL_VALUES_FORM,
  INITIAL_VALUE_PRESENTATIONS,
} from "../../../utils/shared";

export const CreateProductComponent = (props) => {
  const {isView = false, isUpdate = false, item, retrieve, loader} = props;
  const [initialValues, setInitialValue] = useState(INITIAL_VALUES_FORM)
  const [didMount, setMount] = useState(false)
  const nav = useNavigate()
  const {id} = useParams()


  useEffect(() => {
    setMount(true)
  }, []);

  useEffect(() => {
    if (id && didMount) {
      retrieve(id);
    }
  }, [id, didMount, retrieve]);

  useEffect(() => {
    if (id && didMount && item) {
      setInitialValue({...item, hasPresentation: !!item?.presentations?.length})
    }
  }, [id, didMount, item])

  const changeFormArray = (hasPresentation, setFieldValue) => {
    if (hasPresentation) {
      setFieldValue('prices', [])
      setFieldValue('presentations', INITIAL_VALUE_PRESENTATIONS)
    } else {
      setFieldValue('prices', INITIAL_VALUE_PRICE)
      setFieldValue('presentations', [])
    }
  }

  const handleSubmit = (values) => {
    const newPriceIfUndefined = values.prices === undefined ? [] : values.prices;
    const newPresentationIfUndefined = values.presentations === undefined ? [] : values.presentations;


    const processedValues = {
      ...values,
      prices: newPriceIfUndefined,
      presentations: newPresentationIfUndefined
    }
    if (id) {
      if (typeof values.image === 'object') {
        props.updateAttach(id, processedValues, nav)
      } else {
        delete processedValues.image;
        props.update(id, processedValues, nav)
      }
    } else {
      if (typeof values.image === 'object') {
        props.createAttach(processedValues, nav)
      } else {
        props.create(processedValues, nav)
      }
    }
  }

  const schemaProductWithOrWithoutPresentation = Yup.object().shape({
    name: Yup.string().required('Nombre de producto requerido'),
    category: Yup.string().required('Categoría requerida'),
    presentations: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required('Nombre de presentación requerido'),
        // description: Yup.string().required('Descripción requerida'),
        prices: Yup.array().of(
          Yup.object().shape({
            price_type: Yup.string().required('Debe de colocar un tipo de precio requerido'),
            amount: Yup.number().required('Debe de colocar un monto requerido'),
          })
        )
      })),
  });
  const goBack = () => nav('/producto');

  return (
    <>
      <ContainerComponent
        title={isUpdate ? "Editar Producto" : isView ? `Producto ${id}` : "Crear Producto"}>
        <LoaderEllipsisComponent loader={loader}/>
        <Formik
          enableReinitialize={true}
          validateOnMount={true}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={schemaProductWithOrWithoutPresentation}
          component={(formProps) =>
            <FormMainProduct
              {...formProps}
              changeAction={changeFormArray}
              goBack={goBack}
              isUpdate={isUpdate}
              isView={isView}/>
          }/>
      </ContainerComponent>
    </>
  )
}

