import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {actions} from '../../js/redux/dashboard';
import {Card, Typography} from "@material-tailwind/react";
import {Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer} from 'recharts';
import {LineChart} from '@mui/x-charts/LineChart';
import {CardStatistics} from "./CardStatistics";
import {BanknotesIcon, ShoppingCartIcon, UserGroupIcon, ShoppingBagIcon} from '@heroicons/react/24/solid'
import {RecentOrders} from "./RecentOrders";
import {useLocation} from "react-router-dom";
import {TITLES} from "../../config/texts";
import {setMoney} from "../../utils/shared";
import {BarChart} from "@mui/x-charts";
import {PieChart} from '@mui/x-charts/PieChart';


function DashboardComponent(props) {
  const [didMount, setMount] = useState(false);
  const [hasPermission, setPermission] = useState(false);
  const location = useLocation();
  const {dashboard, getDataDashboard} = props

  useEffect(() => {
    setMount(true)
  }, [])

  useEffect(() => {
    if (didMount) validatePermission(true)
  }, [didMount])

  const validatePermission = () => {
    if (location) {
      const views = localStorage.getItem('views')
      if (views === 'is_superuser') {
        setPermission(true)
        getDataDashboard()
      } else if (views) {
        const jsonView = JSON.parse(views)
        jsonView.forEach((view) => {
          if (location.pathname === view.view_f && view.create) {
            setPermission(true)
          }
        })
      }
    }
  }


  return (<>
    {(hasPermission && Object.keys(dashboard).length) ? <div>
      <div className="grid grid-cols-1 lg:grid-cols-4 xl:grid-cols-4 gap-3 px-0 xl:px-6  md:px-4 lg:px-0">
        <CardStatistics
          title="Total de ventas"
          value={setMoney(parseFloat(dashboard.total_sales))}
          icon={<BanknotesIcon className="w-5 h-5"/>}
          legend={`+${dashboard.percentage_sales}` + "% del mes pasado"}
          // background={"bg-gradient-to-l from-green-700 to-green-600"}
          textcolor={"text-black"}
          textColorPargraph={"text-green"}
        />
        <CardStatistics
          title="Ventas realizadas este mes"
          value={`${setMoney(parseFloat(dashboard.total_sales_per_month.total_sales_current_month))}`}
          icon={<ShoppingCartIcon className="w-5 h-5"/>}
          legend={`${setMoney(parseFloat(dashboard.total_sales_last_month.total_sales_last_month))} del mes pasado`}
        />
        <CardStatistics
          title="Total de compras en el mes"
          value={setMoney(parseFloat(dashboard.total_purchase))}
          icon={<ShoppingBagIcon className="w-5 h-5"/>}
          legend={`${setMoney(parseFloat(dashboard.total_purchases.p_last_month.total_purchases_last_month))} del mes pasado`}
        />
        <CardStatistics
          title="Clientes nuevos"
          value={dashboard.new_customer}
          icon={<UserGroupIcon className="w-5 h-5"/>}
          legend={`${dashboard.percentage_new_customer}% de incremento`}
        />
        <div className="col-span-1 lg:col-span-2">
          <ITEMCARD data={dashboard.total_sales_in_current_month}/>
        </div>
        <div className="col-span-1 lg:col-span-2">
          <RecentOrders/>
        </div>
        <div className="col-span-1 lg:col-span-2">
          <GraficaDeBarras data={dashboard.top_10_best_selling_products}/>
        </div>
        <div className={"col-span-1 lg:col-span-2"}>
          <GraficaDePastel data={dashboard.average_sales_by_weekday}/>
        </div>
      </div>
      <Typography variant="h2" className="opacity-10 text-center mt-4"> {TITLES.APP_NAME}</Typography>
    </div> : <div className="p-5">
      <Typography variant="h1"> Bienvenido a tu espacio de trabajo </Typography>
      <Typography variant="small"> En la parte izquierda encontralas las vistas a las cuales tienes
        acceso</Typography>
      <div className="min-h-[40vh] h-full flex flex-row justify-center align-middle">
        <Typography variant="h2" className="opacity-10 text-center my-auto"> {TITLES.APP_NAME}</Typography>
      </div>
    </div>}
  </>)
}


const ITEMCARD = ({data}) => {
  //Data, tiene lo siguiente estructura.
  /*{ "date": "2024-10-12T15:59:45.820134-06:00", "total_sale": "996.00"}*/
  //! Nota: La fecha por ideal no debe ser un arreglo de arreglos, pero ahora por la estructura de la gráfica se hace así, en un futuro se cambiará. y se
  // dejara como una cadena de texto nada mas, ahora por lo que se repite muchas fechas se hace asi.
  const arrayFechas = data.map((item) => [new Date(item.date).toLocaleDateString('es-MX', {
    day: 'numeric', month: 'short', year: 'numeric',
  })])
  const arrayTotalVentas = data.map((item) => item.total_sale)
  return (<Card className="w-full h-[500px] border rounded-lg py-4 pr-4">
    <Typography variant="h5" color="black" className="mx-auto">
      {`Ventas de la fecha ${arrayFechas[0]} al ${arrayFechas[arrayFechas.length - 1]}`}
    </Typography>
    <div className="w-full h-full">
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          xAxis={[{
            label: 'Fecha', data: arrayFechas, scaleType: 'band',
            valueFormatter: (value) => value.toLocaleString()
          }]}
          series={[{
            label: 'Valor de la venta', data: arrayTotalVentas, connectNulls: true
          }]}
          width={500}
          height={300}
          colors={['#089b02']}
          grid={{
            horizontal: true,
          }}
        />
      </ResponsiveContainer>
    </div>
  </Card>)
}

const GraficaDeBarras = ({data}) => {
  const arrayProductNames = data.map((item) => item.product_name + '/' + item.product_presentation)
  const arrayProductTotal = data.map((item) => item.total)
  return (<Card className="w-full h-[500px] border rounded-lg py-4 pr-4">
    <Typography variant="h5" color="black" className="mx-auto">
      {`Productos mas vendidos`}
    </Typography>
    <div className="w-full h-full">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          xAxis={[{scaleType: 'band', data: arrayProductNames, label: 'Productos/Presentacion'}]}
          series={[{
            label: 'Total de ventas',
            data: arrayProductTotal,
          }
          ]}
          width={500}
          height={300}
          colors={['#2290e8']}
          grid={{
            horizontal: true,
          }}
        />
      </ResponsiveContainer>
    </div>
  </Card>)
}

const GraficaDePastel = ({data}) => {
  /*
  * Data tiene la siguiente estructura
  * "average_sales_by_weekday": [
        {
            "weekday": 2,
            "average_sales": "1351.15"
        },
        {
            "weekday": 7,
            "average_sales": "996.00"
        }
    ]
  * */
  const selectDays = ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado']
  const objectData = data.map((item) => {
    return {
      label: selectDays[item.weekday],
      value: item.average_sales
    }
  });
  return (<Card className="w-full h-[500px] border rounded-lg py-4 pr-4">
    <Typography variant="h5" color="black" className="mx-auto">
      Promedio de ventas por día de la semana
    </Typography>
    <div className="w-full h-full">
      <ResponsiveContainer width="100%" height="100%">
        <PieChart series={[{
          startAngle: -90,
          endAngle: 90,
          paddingAngle: 5,
          innerRadius: 60,
          outerRadius: 80,
          data: objectData
        }]}
        />
      </ResponsiveContainer>
    </div>
  </Card>)
}
//  Configuración de redux
const StatesRedux = (state) => {
  return {
    user: state.user.data, dashboard: state.dashboard.data,
  };
};

const DashboardComponentConn = connect(StatesRedux, actions)(DashboardComponent);

export {
  DashboardComponentConn,
}