import {SearchInput} from "./SearchInput";
import {MultiSelectComponent} from "../Inputs/SelectComponent";
import React from "react";
import {SimpleDateComponent} from "../Inputs/SimpleDateComponent";


export const TableSearchAndFilter = (props) => {
  const {filters, changeFilter} = props;

  return (
    <div
      className="grid grid-cols-1 pt-5 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-6 lg:xl:2xl:gap-4 px-2 lg:xl:2xl:px-4">
      <div className="col-span-1 w-full">
        <SearchInput
          onClickSearch={props.searchChange}
          searchValue={props.searchValue}
        />
      </div>
      {
        filters?.map((filter, index) => {
          const {urlOptionList, options, name_filter} = filter;
            if (urlOptionList || options) {
              return <MultiSelectComponent
                key={index}
                dependence={filter.dependence}
                options={filter.options ?? []}
                onChange={(newVal) => {
                  if (changeFilter) {
                    let DynamicObject = {};
                    DynamicObject[name_filter] = !newVal ? '' : newVal.value;
                    changeFilter(DynamicObject)
                  }
                }}
                placeholder={filter.placeholder}
                urlOptionList={filter.urlOptionList}
              />
            } else {
              return <SimpleDateComponent
                key={index}
                {...filter}
                callOnChange={(newVal) => {
                  if (changeFilter) {
                    let dynamicObject = {};
                    dynamicObject[name_filter] = !newVal ? '' : newVal;
                    changeFilter(dynamicObject)
                  }
                }}
              />
            }
          }
        )
      }
    </div>
  )
}
