import { connect } from 'react-redux';
import { CreateSales } from './Create/CreateSalesMain';
import { ListSales } from './List/ListSales';
import { actions } from '../../js/redux/sales';

const salesState = (state) => {
  return {
    ...state.sales,
    user: state.user.data,
    loaderCashRegister: state.cashRegiter.loader,
  };
};


const CreateSalesConn = connect(salesState, actions)(CreateSales);
const ListSalesConn = connect(salesState, actions)(ListSales);

export {
  CreateSalesConn,
  ListSalesConn,
}